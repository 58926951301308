import {useFormikContext} from "formik";
import {Col, Form} from "react-bootstrap";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    telegramBotApiKey: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SettingsForm() {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="telegramBotApiKey">
                <Form.Label>Телеграм бот api ключ</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="1111111111:XXXXX"
                    name="telegramBotApiKey"
                    value={values.telegramBotApiKey}
                    onChange={handleChange}
                    isInvalid={!!errors.telegramBotApiKey}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.telegramBotApiKey}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="crawlerCaptchaSolverApiKey">
                <Form.Label>Api ключ от сервиса решения капчи</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Capmonster ключ api"
                    name="crawlerCaptchaSolverApiKey"
                    value={values.crawlerCaptchaSolverApiKey}
                    onChange={handleChange}
                    isInvalid={!!errors.crawlerCaptchaSolverApiKey}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.crawlerCaptchaSolverApiKey}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg="6" className="mb-3" controlId="rknCheckMode">
                <Form.Label>Режимы проверки сайтов</Form.Label>
                <Form.Select
                    name="rknCheckMode"
                    value={values.rknCheckMode}
                    onChange={ handleChange }
                    isInvalid={ !!errors.rknCheckMode }
                    aria-label=""
                >
                    {Object.entries(values.rknCheckModeList ?? {})?.map(([value, label]) => <option value={value}>{label}</option>)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.rknCheckMode }
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg="6" className="mb-3" controlId="crawlerLaunchInterval">
                <Form.Label>Период запуска пинг проверки</Form.Label>
                <Form.Select
                    name="crawlerLaunchInterval"
                    value={values.crawlerLaunchInterval}
                    onChange={ handleChange }
                    isInvalid={ !!errors.crawlerLaunchInterval }
                    aria-label=""
                >
                    {Object.entries(values.crawlerLaunchIntervalList ?? {})?.map(([value, label]) => <option value={value}>{label}</option>)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.crawlerLaunchInterval}
                </Form.Control.Feedback>
            </Form.Group>

        </Form>
    )
}
