import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";
import ModalContextProvider from "../../../context/ModalContext";
import {ButtonGroup, Card, Table} from "react-bootstrap";
import {ApiAddButton, ApiConfirmModalDialog, ApiPaginator, ApiWrapper} from "../../../components/ApiWrapper";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import ProxiesForm, {validationSchema} from "./ProxiesForm";
import {truncateString} from "../../../utils/helper";
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";

function Proxies() {
    return (
        <ApiWrapperContextProvider route='proxies'>
            <ModalContextProvider>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className="d-flex justify-content-between">
                                Список прокси
                                <ApiAddButton size="sm" variant="outline-dark" modalId="modalWithForm">Добавить</ApiAddButton>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            <Table responsive borderless size="sm">
                                <thead>
                                <tr>
                                    <th>Хост</th>
                                    <th>Порт</th>
                                    <th>Логин</th>
                                    <th>Пароль</th>
                                    <th>Статус</th>
                                    <th>Список</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ApiWrapper>
                                    {({fetchedData, handleConfirmDelete}) => fetchedData?.items?.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.host}</td>
                                            <td>{item.port}</td>
                                            <td>{truncateString(item.user, 20)}</td>
                                            <td>{item.password}</td>
                                            <td>{item.status}</td>
                                            <td>{item.listName}</td>
                                            <td width="20">
                                                <ButtonGroup className="mb-2">
                                                    <ButtonDelete onClick={() => handleConfirmDelete(item, 'modalConfirm')}/>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ApiWrapper>
                                </tbody>
                            </Table>
                            <ApiPaginator/>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <ApiModalFormWrapper windowId="modalWithForm" validationSchema={validationSchema} title="Добавление прокси">
                    <ProxiesForm/>
                </ApiModalFormWrapper>
                <ApiConfirmModalDialog windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalDialog>
            </ModalContextProvider>
        </ApiWrapperContextProvider>
    )
}

export default Proxies;
