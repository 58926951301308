import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNoteSticky} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../../../components/buttons/Button.js"

function ButtonNote({onClick}) {
    return (
        <BaseButton placement="top" tooltip="Показать примечание">
            <Button size="sm" onClick={onClick} variant="outline-primary">
                <FontAwesomeIcon icon={faNoteSticky}/>
            </Button>
        </BaseButton>
    );
}

export default ButtonNote;