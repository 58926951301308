import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSitemap} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../../../components/buttons/Button.js"

function ButtonCloudflareDns({onClick}) {
    return (
        <BaseButton placement="top" tooltip="Редактор DNS записей Cloudflare">
            <Button size="sm" onClick={onClick} variant="outline-success">
                <FontAwesomeIcon icon={faSitemap} />
            </Button>
        </BaseButton>
    );
}

export default ButtonCloudflareDns
