import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDice, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import ButtonOverlay from "../buttons/ButtonOverlay";
import {generatePassword} from "../../utils/helper";

const buttonStyle = {
    "color": "black",
    "borderColor": "#dee2e6"
};

export default function PasswordInput({errors, onChange, value, name, beforeInput, afterInput, withPasswordGenerator = false, ...rest}) {
    const [isHidden, setIsHidden] = useState(true);
    const [passwordValue, setPasswordValue] = useState(value);

    const handlePasswordRandom = () => {
        const newPassword = generatePassword(16);
        setPasswordValue(newPassword);
        onChange(newPassword);
    }

    return <InputGroup className="mb-3">
        {beforeInput}
        <Form.Control
            name={name}
            value={passwordValue}
            onChange={(event) => {
                onChange(event.target.value);
                setPasswordValue(event.target.value)
            }}
            type={isHidden ? "password" : "text"}
            isInvalid={!!errors[name]}
            {...rest}
        />
        <ButtonOverlay tooltip={`${isHidden ? 'Показать' : 'Скрыть'} пароль`}>
            <Button
                style={buttonStyle}
                variant="outline-light"
                onClick={() => setIsHidden(!isHidden)}
            >
                <FontAwesomeIcon icon={isHidden ? faEye : faEyeSlash}/>
            </Button>
        </ButtonOverlay>
        {withPasswordGenerator && <ButtonOverlay tooltip="Сгенерировать пароль">
            <Button
                style={buttonStyle}
                variant="outline-light"
                onClick={handlePasswordRandom}
            >
                <FontAwesomeIcon icon={faDice}/>
            </Button>
        </ButtonOverlay>}
        <Form.Control.Feedback type="invalid">
            {errors[name]}
        </Form.Control.Feedback>
        {afterInput}
    </InputGroup>
}