import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import PasswordInput from "../../components/form/PasswordInput";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Поле обязательно для заполнения.'),
    password: Yup.string().required('Поле обязательно для заполнения.'),
    passwordConfirmation: Yup.string().required('Поле обязательно для заполнения.')
})

export default function ChangePasswordForm() {
    const {values, setFieldValue, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="oldPassword">
                <Form.Label>Введите ваш старый пароль</Form.Label>
                <PasswordInput
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={(value) => setFieldValue('oldPassword', value)}
                    errors={errors}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Введите ваш новый пароль</Form.Label>
                <PasswordInput
                    name="password"
                    value={values.password}
                    onChange={(value) => setFieldValue('password', value)}
                    errors={errors}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="passwordConfirmation">
                <Form.Label>Повторите ввод пароля</Form.Label>
                <PasswordInput
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={(value) => setFieldValue('passwordConfirmation', value)}
                    errors={errors}
                />
            </Form.Group>
        </Form>
    )
}
