export function crudBuilder({pattern, headers, hasId = true}) {

    const buildUrl = (bindings) => {

        let url = pattern;
        let params = bindings;
        for (const bindParam in params) {
            //if (typeof bindings[bind] === 'string') {
            const bindKey = ':' + bindParam;
            if (url.includes(bindKey)) {
                url = url.replace(bindKey, params[bindParam])
            }
            //}
        }

        return {url, params}
    }

    return (type, bindings) => {

        // type: method
        const typeMap = {
            'create': 'post',
            'request': 'get',
            'update': 'put',
            'delete': 'delete'
        };

        if (!(type in typeMap)) {
            throw new Error(`Unknown ${type}`)
        }

        let {url, params} = buildUrl(bindings);

        if (['update', 'delete'].includes(type) && hasId) {
            if (!('id' in bindings)) {
                throw new Error('The route must have a binding value for id!')
            }

            url += '/' + bindings['id'];
        }


        return {method: typeMap[type], url, params, headers}
    }
}