import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import {useContext} from "react";
import {Formik} from "formik";
import {AuthContext} from "../../context/AuthContext";
import * as Yup from "yup";
import PasswordInput from "../../components/form/PasswordInput";
import {faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const validationSchema = Yup.object().shape({
    userName: Yup.string().required('Поле обязательно для заполнения.'),
    password: Yup.string().required('Поле обязательно для заполнения.')
})

export default function Login() {
    const {error, handleLogin} = useContext(AuthContext)

    return (
        <div className="d-flex justify-content-center align-items-center" style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundImage: "linear-gradient(to right, #fc5c7d, #6a82fb)"
        }}>
            <div className="p-5" style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "gray",
                backgroundColor: "#fff",
                width: "430px"
            }}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{userName: "", password: ""}}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                >
                    {({errors, values, handleChange, setFieldValue, handleSubmit}) => (
                        <>
                            <Alert show={error === 'Unauthorized'} variant="danger">Логин или пароль введны
                                неверно</Alert>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="loginFormEmail">
                                    <Form.Label>Логин</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faUser}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="userName"
                                            value={values.userName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.userName}
                                            placeholder="Введите логин"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.userName}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="loginFormPassword">
                                    <Form.Label>Пароль</Form.Label>
                                    <PasswordInput
                                        beforeInput={<InputGroup.Text><FontAwesomeIcon icon={faKey}/></InputGroup.Text>}
                                        name="password"
                                        value={values.password}
                                        onChange={(value) => setFieldValue('password', value)}
                                        errors={errors}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Войти
                                </Button>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}