import {Alert} from "react-bootstrap";

export default function AlertDismissible({show, children, onClose, ...rest}) {
    if (show) {
        return (
            <Alert {...rest} onClose={onClose} dismissible>
                {children}
            </Alert>
        );
    }
}