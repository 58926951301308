import {useContext, useEffect} from "react";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";
import ApiManager from "../../api";
import {ModalContext} from "../../context/ModalContext";

export default function ApiWrapper({routeData, children}) {

    const {
        setFetchedData,
        fetchedData,
        route,
        routeParams,
        editItem,
        deleteItem,
        currentPage,
        setCurrentPage,
        lastFetchedDate
    } = useContext(ApiWrapperContext)

    const newRouteParams = {
        ...routeParams,
        ...routeData
    }

    const {openModal} = useContext(ModalContext);
    const routeParamsDependency = JSON.stringify(newRouteParams);

    const handleEdit = (item, modalId) => {
        editItem(item);
        if (modalId) {
            openModal(modalId)
        }
    };

    const handleConfirmDelete = (item, modalId) => {
        deleteItem(item);
        if (modalId) {
            openModal(modalId)
        }
    };


    useEffect(() => {

        (new ApiManager())
            .request(route, {
            ...newRouteParams,
            page: currentPage || 1
        }).then(data => {
            let currentPage = data?.current_page;
            let totalPages = data?.last_page;

            if (currentPage > totalPages) {
                currentPage = 1;
                setCurrentPage(currentPage)
            }

            setFetchedData({
                paginator: {
                    currentPage,
                    totalPages
                },
                items: data?.data
            });
        });
    // eslint-disable-next-line
    }, [lastFetchedDate, route, currentPage, routeParamsDependency]);

    const childrenValues = {
        fetchedData,
        handleEdit,
        handleConfirmDelete
    }

    return (
        <>
            { fetchedData ? children(childrenValues) : "Пусто" }
        </>
    )
}