import {Card} from "react-bootstrap";
import UserSettingsStatForm from "./UserSettingsStatForm";

export default function UserSettings() {

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>Настройка XmlStock</Card.Title>
                    <UserSettingsStatForm/>
                </Card.Body>
            </Card>
        </>
    )
}