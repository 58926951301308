import {createContext, useState} from "react";

export const FilterContext = createContext(undefined);

export default function FilterContextProvider({defaultFilterValues, children}) {

    const [filters, setFilters] = useState(defaultFilterValues && {});
    const [sortColumns, setSortColumns] = useState([]);

    const contextValues = {
        filters,
        setFilters,
        isFiltersEnabled: true,
        sortColumns,
        setSortColumns
    }

    return (
        <FilterContext.Provider value={contextValues}>
            {children}
        </FilterContext.Provider>
    )
}