import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useEffect, useState} from "react";
import useDebounce from "../../../utils/debounce";
import ApiManager from "../../../api";
import {formatDateTime} from "../../../utils/helper";

export default function ValidationStatus({id, status, rknCheckAt}) {

    const [showed, setShowed] = useState(false);
    const [domainData, setDomainData] = useState(null)

    const debouncedRequest = useDebounce(() => {
        if (!showed) return;

        new ApiManager()
            .request('sites.site.rkn-status', { siteId: id })
            .then((data) => {
                setDomainData(data.data)
            })

    }, 30000, { leading: true });

    const statuses = {
        'valid': 'text-success',
        'banned': 'text-danger'
    };

    const statusNames = {
        'valid': 'ОК',
        'banned': 'Заблокирован'
    }

    const statusesIco = {
        'valid': faCheck,
        'banned': faBan
    }

    useEffect(() => {
        if (!showed) return;
        debouncedRequest();
    }, [id, showed])

    const popover = (
        <Popover id="popover-block">
            <Popover.Header as="h3">Статус домена в базе РКН</Popover.Header>
            <Popover.Body>
                <p>{domainData ?
                <>Домен <strong>{domainData.domain}</strong>: {statusNames[domainData.status]}</> :
                    'Загрузка...'}</p>
                <p>Последняя проверка: {rknCheckAt ? formatDateTime(rknCheckAt) : '-'}</p>
            </Popover.Body>
        </Popover>
    );

    return <OverlayTrigger trigger="hover" onToggle={() => setShowed(!showed)} placement="top" overlay={popover}>
            <FontAwesomeIcon
                className={`${statuses[status] ?? 'text-info'} fw-bolder`}
                icon={statusesIco[status] ?? faCircleXmark}/>
        </OverlayTrigger>
}
