import ButtonOverlay from "../../../../components/buttons/ButtonOverlay";
import {Button, Col, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Field, ErrorMessage, useFormikContext} from 'formik';


function CloudflareDns({index, place, handleRemove}) {
    const {values, setFieldValue} = useFormikContext();

    return <div key={index} className="d-flex justify-content-around">

        <div className="col-10">
            <div className="d-flex justify-content-start align-items-baseline">
                <span className="m-2">{place}.</span>
                <div className="m-2" style={{'width': '100%'}}>
                    <div className="d-flex flex-row justify-content-start align-items-baseline mb-2">
                        <div className="input-group has-validation">
                            <Field
                                as="select"
                                className="form-select"
                                name={`records.${index}.type`}
                                style={{'width': '25%', 'margin-right': '.5rem'}}
                                aria-label="Тип"
                            >
                                <option key="a" value="A">A</option>
                                <option key="aaaa" value="AAAA">AAAA</option>
                                <option key="cname" value="CNAME">CNAME</option>
                                <option key="txt" value="TXT">TXT</option>
                                <option key="uri" value="URI">URI</option>
                            </Field>
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.type`}
                            />
                        </div>
                        <div className="input-group has-validation">
                            <Field
                                className="form-control"
                                name={`records.${index}.name`}
                                type="text"
                                placeholder="Name"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.name`}
                            />
                        </div>
                    </div>
                    {values.records[index].type === 'URI' ?
                        <>
                            <Field
                                className="form-control"
                                name={`records.${index}.target`}
                                aria-label="Цель"
                                type="text"
                                placeholder="Цель"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.target`}
                            />
                        </>
                        :
                        <>
                            <Field
                                className="form-control"
                                name={`records.${index}.content`}
                                aria-label="Content"
                                type="text"
                                placeholder="Content"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.content`}
                            />
                        </>
                    }


                    {values.records[index].type === 'URI' &&
                    <Col sm={4}>
                        <div className="input-group has-validation" style={{'margin-top': '.5rem'}}>
                            <Field
                                className="form-control"
                                name={`records.${index}.weight`}
                                type="text"
                                placeholder="Вес (0-65535)"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.weight`}
                            />
                        </div>
                        <div className="input-group has-validation" style={{'margin-top': '.5rem'}}>
                            <Field
                                className="form-control"
                                name={`records.${index}.priority`}
                                type="text"
                                placeholder="Приоритет (0-255)"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`records.${index}.priority`}
                            />
                        </div>
                    </Col>
                    }
                </div>
            </div>
        </div>
        <div className="col-2">
            <div className="d-flex flex-column justify-content-start align-items-center">
                <div className="d-flex m-2">
                    <Field name={`records.${index}.isProxied`}>
                        {({field}) => <Form.Check
                            type="switch"
                            disabled={['URI', 'TXT'].includes(values.records[index].type)}
                            checked={field.value}
                            onChange={event => setFieldValue(field.name, event.target.checked)}
                            label="Proxied"
                        />}
                    </Field>
                </div>
                <div className="d-flex m-2">
                    <ButtonOverlay className="flex-grow-1" tooltip="Удалить">
                        <Button onClick={handleRemove} size="sm" variant="danger" className="m-1">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </ButtonOverlay>
                </div>
            </div>
        </div>
    </div>
}

export default CloudflareDns
