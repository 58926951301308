import {Card} from "react-bootstrap";
import {ApiPaginator, ApiWrapper} from "../components/ApiWrapper";
import DataGridWrapper from "../components/DataGridWrapper";
import ModalContextProvider from "../context/ModalContext";
import {formatDateTime} from "../utils/helper";

function DashboardExpiresDomain() {

    const statuses = {
        'mirror': 'Забаненное зеркало',
        'main': 'Основной',
        'backup': 'Резервный'
    };

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен'
            },
            {
                key: 'domainType',
                name: 'Статус',
                renderCell: ({row}) => statuses[row.domainType] ?? ''
            },
            {
                key: 'expirationDate',
                name: 'Истекает',
                renderCell: ({row}) => {
                    const isExpired = Date.now() > Date.parse(row.expirationDate);
                    if (isExpired) {
                        return (<div style={{'color': 'red'}}>{formatDateTime(row.expirationDate)}</div>)
                    }

                    return (<div>{formatDateTime(row.expirationDate)}</div>)
                }
            }
        ]
    }

    return (
        <Card>
            <Card.Header>Домены с истекающим сроком регистрации</Card.Header>
            <Card.Body>
                <Card.Title>Список доменов</Card.Title>
                <ModalContextProvider>
                    <ApiWrapper>
                        {(props) => <DataGridWrapper
                            defaultColDef={{flex: 1}}
                            columns={getColumns()}
                            rows={props.fetchedData.items}
                        />}
                    </ApiWrapper>
                    <ApiPaginator size="sm"/>
                </ModalContextProvider>
            </Card.Body>
        </Card>
    )
}

export default DashboardExpiresDomain;