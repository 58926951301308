import {useFormikContext} from "formik";
import {useEffect, useState} from "react";
import {Form, Stack} from "react-bootstrap";

function CloudflareDependentSelect({cloudflareProfile}) {
    const {values, setFieldValue, handleChange, errors} = useFormikContext();
    const [cloudflareProfiles, setCloudflareProfiles] = useState([]);
    const [isGroupChange, setIsGroupChange] = useState(false);

    useEffect(() => {

        const cloudflareId = values.cloudflareId;
        const cloudflareGroupId = values.cloudflareGroupId;

        if (cloudflareId === 0) {
            setFieldValue('cloudflareId', '');
        }

        if (cloudflareGroupId === 0) {
            setFieldValue('cloudflareGroupId', '');
        }

        if (isGroupChange) {
            setFieldValue('cloudflareId', '');
            setIsGroupChange(false);
        }

        if (!cloudflareGroupId) {
            setCloudflareProfiles(cloudflareProfile?.profiles);
        }

        if (cloudflareGroupId) {
            setCloudflareProfiles(cloudflareProfile?.profiles?.filter(profile => profile.group.id === parseInt(cloudflareGroupId)));
        }

    }, [isGroupChange, cloudflareProfile.profiles, values.cloudflareId, values.cloudflareGroupId]);

    return <Stack gap={2}>
        <Form.Select
            name="cloudflareGroupId"
            value={values.cloudflareGroupId}
            onChange={(e) => {
                setIsGroupChange(true)
                handleChange(e);
            }}
            isInvalid={!!errors.cloudflareGroupId}
            aria-label="Выберите группу CloudFlare"
        >
            <option value="">Выберите группу CloudFlare</option>
            {cloudflareProfile.groups?.map(cloudflare => (
                <option key={cloudflare.id} value={cloudflare.id}>{cloudflare.title}</option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            {errors.cloudflareGroupId}
        </Form.Control.Feedback>

        <Form.Select
            name="cloudflareId"
            value={values.cloudflareId}
            onChange={handleChange}
            isInvalid={!!errors.cloudflareId}
            aria-label="Выберите аккаунт CloudFlare (опция)"
        >
            <option value="">Выберите профиль CloudFlare (не обязательно)</option>
            {cloudflareProfiles?.map(cloudflare => (
                <option key={cloudflare.id} value={cloudflare.id}>{cloudflare.title} ({cloudflare.email}) домены: {(typeof cloudflare?.domainsWithCategory !== "undefined") ? cloudflare.domainsWithCategory + '/': ''}{cloudflare.domains}</option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            {errors.cloudflareId}
        </Form.Control.Feedback>
    </Stack>
}

export default CloudflareDependentSelect;
