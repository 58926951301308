import {ApiPaginator, ApiWrapper} from "../../../../components/ApiWrapper";
import DataGridWrapper from "../../../../components/DataGridWrapper";
import {formatDateTime} from "../../../../utils/helper";
import styles from "../../SiteCellBackground.module.css";
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import {Button, Modal} from "react-bootstrap";
import {useContext, useState} from "react";
import {ApiWrapperContext} from "../../../../context/ApiWrapperContext";
import ApiManager from "../../../../api";

function SitesListHistory({siteId}) {

    const [isConfirmWindowOpen, setIsConfirmWindowOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const {setLastFetchedDate} = useContext(ApiWrapperContext)

    function handleOnClose() {
        setIsConfirmWindowOpen(false);
        setCurrentItem(null)
    }

    function handleOnConfirm() {
        new ApiManager()
            .delete('sites.site.history', {}, {siteId, id: currentItem.id})
            .then((data) => {
                handleOnClose();
                setLastFetchedDate(new Date())
            })
    }

    function handleConfirmDelete(row) {
        return () => {
            setIsConfirmWindowOpen(true);
            setCurrentItem(row);
        }
    }

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                width: '22%',
            },
            {
                key: 'cloudflareEmail',
                name: 'Cloudflare Email',
                width: '25%',
            },
            {
                key: 'registrarName',
                name: 'Профиль регистратора',
                width: '25%',
            },
            {
                key: 'note',
                name: 'Примечание',
                width: '15%',
            },
            {
                key: 'transferAt',
                name: 'Дата переезда',
                width: 150,
                renderCell({row}) {
                    return formatDateTime(row['transferAt'])
                }
            },
            {
                key: 'action',
                width: 50,
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return <ButtonDelete onClick={handleConfirmDelete(row)}/>
                }
            }
        ];
    }

    return <>
        <ApiWrapper>
            {(props) => <DataGridWrapper
                columns={getColumns(props)}
                rows={props.fetchedData.items}
            />}
        </ApiWrapper>

        <Modal backdrop="static" show={isConfirmWindowOpen} onHide={handleOnClose}>
            <Modal.Header closeButton>
                <Modal.Title>Подтверждение удаления</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h3 className="text-center">Вы уверены что хотите удалить домен из истории?</h3>
                <p className="text-center text-danger">Это действие нельзя отменить.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={handleOnClose}>Нет</Button>
                <Button variant="danger" onClick={handleOnConfirm}>Да</Button>
            </Modal.Footer>
        </Modal>

        <ApiPaginator size="sm"/>
    </>
}

export default SitesListHistory;
