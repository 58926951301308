import {Button, ButtonGroup, Card, Col, Row} from "react-bootstrap";
import {formServerValidate} from "../../utils/helper";
import {Formik} from "formik";
import NotificationForm, {validationSchema} from "./NotificationForm";
import ApiManager from "../../api";
import {useEffect, useState} from "react";
import AlertDismissible from "../../components/AlertDismissible";
import DataGridWrapper from "../../components/DataGridWrapper";
import styles from "../Sites/SiteCellBackground.module.css";
import ButtonDelete from "../../components/buttons/ButtonDelete";

export default function Notification() {
    const [fetchedData, setFetchedData] = useState({});
    const [lastUpdated, setLastUpdated] = useState(Date.now())
    const [isSaved, setIsSaved] = useState(false)

    const initialFieldValues = {
        telegramUserName: '',
    }

    const handleSubmit = async (values, actions) => await formServerValidate(async () => {
        await new ApiManager().create('notification.telegram', values);
        setIsSaved(true)
    }, actions)

    const handleDelete = async (row) => {
        await new ApiManager().delete('notification.telegram', row, {
            id: row.id,
        });

        setLastUpdated(Date.now())
    }

    useEffect(() => {
        new ApiManager()
            .request('notification.telegram')
            .then(data => setFetchedData(data.data))
    }, [isSaved, lastUpdated])

    function getColumns() {
        return [
            {
                key: 'userName',
                name: 'Логин'
            },
            {
                key: 'telegramId',
                name: 'Телеграм ID'
            },
            {
                key: 'action',
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return (
                        <ButtonGroup className="mb-2">
                            <ButtonDelete onClick={() => handleDelete(row)}/>
                        </ButtonGroup>
                    )
                }
            }
        ];
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Уведомления</Card.Title>
            </Card.Header>
            <Card.Body>
                <AlertDismissible
                    show={isSaved}
                    onClose={() => setIsSaved(false)}
                    variant="success">
                    Настройки изменены
                </AlertDismissible>
                <Card.Title>Телеграм</Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={12} lg={6}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialFieldValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({handleSubmit}) => (
                                    <>
                                        <NotificationForm/>
                                        <Button size="sm" variant="primary" onClick={handleSubmit}>
                                            Добавить
                                        </Button>
                                    </>
                                )}
                            </Formik>
                            {fetchedData.length > 0 &&
                                <>
                                    <hr/>
                                    <DataGridWrapper
                                        defaultColDef={{flex: 1}}
                                        columns={getColumns()}
                                        rows={fetchedData}
                                    />
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
