import {Button, Card, Col, Row} from "react-bootstrap";
import ChangePasswordForm, {validationSchema} from "./ChangePasswordForm";
import {formServerValidate} from "../../utils/helper";
import {Formik} from "formik";
import ApiManager from "../../api";
import {useState} from "react";
import AlertDismissible from "../../components/AlertDismissible";

export default function ChangePassword() {

    const [isSaved, setIsSaved] = useState(false)

    const initialFieldValues = {
        oldPassword: '',
        password: '',
        passwordConfirmation: ''
    }

    const handleSubmit = async (values, actions) => await formServerValidate(async () => {
        await new ApiManager().create('change-password', values);
        setIsSaved(true)
    }, actions)

    return (
        <Card>
            <Card.Body>
                <Card.Title>Измененить пароль</Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={12} lg={6}>
                            <AlertDismissible
                                show={isSaved}
                                onClose={() => setIsSaved(false)}
                                variant="success">
                                Пароль изменён
                            </AlertDismissible>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialFieldValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({handleSubmit}) => (
                                    <>
                                        <ChangePasswordForm/>
                                        <Button variant="primary" onClick={handleSubmit}>
                                            Изменить пароль
                                        </Button>
                                    </>
                                )}
                            </Formik>


                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
