import { useFormikContext } from "formik";
import {Form} from "react-bootstrap";
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    email: Yup.string().email('Введите корректный email.').required('Поле обязательно для заполнения.'),
    apiKey: Yup.string().min(10, 'Слишком короткий ключ').required('Поле обязательно для заполнения.'),
    group: Yup.string().required('Поле обязательно для заполнения.'),
    password: Yup.string().notRequired()
})

export default function CloudFlareForm({groups}) {
    const { values, handleChange, errors } = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Название профиля</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="group">
                <Form.Label>Группы</Form.Label>
                <Form.Select
                    name="group"
                    value={values.group}
                    onChange={ handleChange }
                    isInvalid={ !!errors.group }
                    aria-label="Выберите группу"
                >
                    <option value="">Выберите группу</option>
                    {groups.length > 0 ? groups.map(group => (
                        <option key={group.id} value={group.id}>{group.title}</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.group }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    name="email"
                    type="email"
                    value={ values.email }
                    onChange={ handleChange }
                    placeholder="Например: example@mail.com"
                    isInvalid={ !!errors.email }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.email }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="apiKey">
                <Form.Label>Ключ api</Form.Label>
                <Form.Control
                    name="apiKey"
                    value={ values.apiKey }
                    onChange={ handleChange }
                    type="text"
                    placeholder="Например: d249b90ceef68f9087c5b5efaca3957935b70"
                    isInvalid={ !!errors.apiKey }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.apiKey }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Пароль (не обязательно)</Form.Label>
                <Form.Control
                    name="password"
                    value={ values.password }
                    onChange={ handleChange }
                    type="text"
                    isInvalid={ !!errors.password }
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
