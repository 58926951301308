import {Button, ButtonGroup, Card} from "react-bootstrap";
import {useEffect, useState} from "react";
import ApiManager from "../../api";
import DnsUpdateForm, {validationSchema, initialFieldValues} from "./DnsUpdateForm";
import {Formik} from "formik";
import {formServerValidate} from "../../utils/helper";
import AlertDismissible from "../../components/AlertDismissible";

function DnsUpdate()
{
    const [cloudflareProfile, setCloudflareProfile] = useState([])
    const [registrarProfile, setRegistrarProfile] = useState([])
    const [serverProfile, setServerProfile] = useState([]);
    const [isSaved, setIsSaved] = useState(false)

    useEffect(() => {
        const apiManager = new ApiManager();

        apiManager
            .request('cloudflare.profile-list')
            .then(response => {
                setCloudflareProfile(response.data)
            })

        apiManager
            .request('registrar', {
                paginator: 'without'
            })
            .then(data => {
                setRegistrarProfile(data)
            })

        apiManager
            .request('server', {
                paginator: 'without'
            })
            .then(data => {
                setServerProfile(data)
            })

    }, [])

    const handleSubmit = async (values, actions) => await formServerValidate(async () => {
        await new ApiManager().create('domain.dns-update', values);
        setIsSaved(true)
    }, actions)

    return <Card>
        <Card.Body>
            <Card.Title>Добавление доменов и DNS записей в Cloudflare</Card.Title>
            <AlertDismissible
                show={isSaved}
                onClose={() => setIsSaved(false)}
                variant="success">
                Домены добавлены в очередь на обработку.
            </AlertDismissible>
            <Formik
                enableReinitialize={true}
                initialValues={initialFieldValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({handleSubmit, handleReset}) => (
                    <>
                        <DnsUpdateForm
                            registrarProfile={registrarProfile}
                            cloudflareProfile={cloudflareProfile}
                            serverProfile={serverProfile}
                        />
                        <ButtonGroup>
                            <Button onClick={handleSubmit} variant="primary">Запустить</Button>
                            <Button onClick={handleReset} variant="secondary">Очистить</Button>
                        </ButtonGroup>
                    </>
                )}
            </Formik>

        </Card.Body>
    </Card>
}

export default DnsUpdate
