import {useContext} from "react";
import {FilterContext} from "../../context/DataGridFilterContext";
import FilterRenderer from "./FilterRenderer";
import {selectStopPropagation} from "../../utils/helper";

export default function FilterSelect({row, options}) {
    const {setFilters} = useContext(FilterContext)
    const key = row.column.key;

    return (
        <FilterRenderer {...row}>
            {({filters, ...rest}) => {
                return (
                    <select
                        {...rest}
                        value={filters[key] !== undefined ? filters[key] : ''}
                        onChange={(e) => setFilters({
                            ...filters,
                            [key]: e.target.value
                        })}
                        onKeyDown={selectStopPropagation}
                    >
                        {Object.entries(options).map(([value, title]) => <option key={title} value={value}>{title}</option>)}
                    </select>
                )
            }}
        </FilterRenderer>
    )
}