import DataGrid from "react-data-grid";
import 'react-data-grid/lib/styles.css';
import './DataGridWrapper.css';
import {useEffect} from "react";

const gridStyle = {
    minHeight: 400,
    blockSize: "100%"
}

export default function DataGridWrapper({style, defaultColDef, columns, ...rest}) {

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')

                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none')
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none')
                }
            }
        })
    }, [])

    return <DataGrid
        style={{...style, ...gridStyle}}
        className={'rdg-light'}
        columns={columns.map(column => ({...defaultColDef, ...column}))}
        {...rest}
    />
}
