import ButtonOverlay from "../../../../components/buttons/ButtonOverlay";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Field, ErrorMessage, useFormikContext} from 'formik';


function CloudflareRedirectRule({index, place, handleRemove, handleMoveUp, handleMoveDown}) {
    const {values} = useFormikContext();

    return <div key={index} className="d-flex justify-content-around">

        <div className="col-10">
            <div className="d-flex align-items-start justify-content-start align-items-baseline">
                <span className="m-2">{place}.</span>
                <div className="m-2" style={{'width': '100%'}}>
                    <div className="d-flex flex-row justify-content-start align-items-baseline mb-2">
                        <div className="input-group has-validation">
                            <Field
                                as="select"
                                className="form-select"
                                name={`rules.${index}.redirectType`}
                                style={{'width': '25%', 'margin-right': '.5rem'}}
                                aria-label="Тип редиректа"
                            >
                                <option key="static" value="static">Статичный</option>
                                <option key="filter" value="filter">Фильтр</option>
                            </Field>
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`rules.${index}.redirectType`}
                            />
                        </div>
                        <div className="input-group has-validation">
                            <Field
                                className="form-control"
                                name={`rules.${index}.targetUrl`}
                                type="text"
                                placeholder="Url"
                            />
                            <ErrorMessage
                                component="div"
                                className="invalid-feedback"
                                style={{'display': 'block'}}
                                name={`rules.${index}.targetUrl`}
                            />
                        </div>
                    </div>

                    {values.rules[index].redirectType === 'filter' &&
                    <div className="input-group has-validation">
                        <Field
                            className="form-control"
                            name={`rules.${index}.expression`}
                            as="textarea"
                            placeholder="Введите правило"
                            style={{'minHeight': '100px'}}
                        />
                        <ErrorMessage
                            component="div"
                            className="invalid-feedback"
                            style={{'display': 'block'}}
                            name={`rules.${index}.expression`}
                        />
                    </div>
                    }
                </div>
            </div>
        </div>
        <div className="col-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <Field
                    as="select"
                    className="form-select mt-2"
                    name={`rules.${index}.redirectCode`}
                    aria-label="Код редиректа"
                >
                    <option value="301">301</option>
                    <option value="302">302</option>
                    <option value="303">303</option>
                    <option value="307">307</option>
                    <option value="308">308</option>
                </Field>
                <div className="d-flex m-2">
                    <ButtonOverlay tooltip="Понизить приоритет">
                        <Button onClick={handleMoveDown} size="sm" variant="outline-primary" className="m-1">
                            <FontAwesomeIcon icon={faArrowDown}/>
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay tooltip="Повысить приоритет">
                        <Button onClick={handleMoveUp} size="sm" variant="outline-primary" className="m-1">
                            <FontAwesomeIcon icon={faArrowUp}/>
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay className="flex-grow-1" tooltip="Удалить">
                        <Button onClick={handleRemove} size="sm" variant="danger" className="m-1">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </ButtonOverlay>
                </div>
            </div>
        </div>
    </div>
}

export default CloudflareRedirectRule
