import {Button, ButtonGroup, Card, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import ApiManager from "../../api";
import {useState} from "react";

function DomainStatus() {

    const [validDomains, setValidDomains] = useState([])
    const [bannedDomains, setBannedDomains] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    const initialFieldValues = {
        domain: ''
    }

    const validationSchema = Yup.object().shape({
        domain: Yup.string().required('Поле обязательно для заполнения.')
    })

    const onSubmit = async (values) => {
        setIsLoaded(false)
        const response = await new ApiManager().create('domain.check-rkn-status', values);

        const valid = response.data?.domain?.filter((domain) => domain.status === 'valid');
        const banned = response.data?.domain?.filter((domain) => domain.status === 'banned');

        if (valid.length > 0) {
            setValidDomains(valid)
        } else {
            setValidDomains([])
        }

        if (banned.length > 0) {
            setBannedDomains(banned)
        } else {
            setBannedDomains([])
        }

        setIsLoaded(true)
    }

    const resetDomainList = () => {
        setIsLoaded(false);
        setValidDomains([]);
        setBannedDomains([])
    }

    return <Card>
        <Card.Body>
            <Card.Title>Проверка статуса доменов в локальном реестре РКН</Card.Title>

            <Formik
                enableReinitialize={true}
                initialValues={initialFieldValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({handleSubmit, handleReset, handleChange, values, errors}) => (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <Form.Label>Домен или домены списком (Каждый домен с новой строки)</Form.Label>
                            <Form.Control
                                name="domain"
                                as="textarea"
                                type="text"
                                value={values.domain}
                                onChange={handleChange}
                                placeholder={`example1.com\nexample2.com\nexample3.com`}
                                isInvalid={!!errors.domain}
                                autoFocus
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.domain}
                            </Form.Control.Feedback>
                            <ButtonGroup className="mt-2">
                                <Button variant="primary" type="submit">Проверить</Button>
                                <Button variant="secondary" onClick={(e) => {
                                    handleReset(e);
                                    resetDomainList();
                                }}>Очистить</Button>
                            </ButtonGroup>
                        </Form>
                    </>
                )}
            </Formik>
            {isLoaded &&
            <Row className="mt-3">
                <div className="col-sm-6">
                    <h5>Бан РКН</h5>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        {bannedDomains.length === 0 ? 'Домены не найдены' :
                            bannedDomains.map(domain => <div
                                className="p-1 bd-highlight">{domain.domain}</div>)
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <h5>Не забанены</h5>
                    <div className="d-flex flex-column bd-highlight mb-3">
                        {validDomains.length === 0 ? 'Домены не найдены' :
                            validDomains.map(domain => <div
                                className="p-1 bd-highlight">{domain.domain}</div>)
                        }
                    </div>
                </div>
            </Row>
            }
        </Card.Body>
    </Card>
}

export default DomainStatus
