import {useContext} from "react";
import {FilterContext} from "../../context/DataGridFilterContext";
import {inputStopPropagation} from "../../utils/helper";
import FilterRenderer from "./FilterRenderer";

export default function FilterInput({row}) {
    const {setFilters} = useContext(FilterContext)
    const key = row.column.key;

    return (
        <FilterRenderer {...row}>
            {({filters, ...rest}) => {
                return (
                    <input
                        {...rest}
                        value={filters[key] !== undefined ? filters[key] : ''}
                        onChange={(e) => setFilters({
                            ...filters,
                            [key]: e.target.value
                        })}
                        onKeyDown={inputStopPropagation}
                    />
                )
            }}
        </FilterRenderer>
    )
}