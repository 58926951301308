import {OverlayTrigger, Popover} from "react-bootstrap";
import {useEffect, useState} from "react";
import useDebounce from "../../../utils/debounce";
import ApiManager from "../../../api";
import {formatDateTime} from "../../../utils/helper";
import { useClickAway } from "@uidotdev/usehooks";

export default function DomainInfo({id, domainName}) {

    const [showed, setShowed] = useState(false);
    const [domainData, setDomainData] = useState(null)
    const ref = useClickAway(() => {
        setShowed(false);
    });

    const debouncedRequest = useDebounce(() => {
        if (!showed) return;

        new ApiManager()
            .request('sites.site.domain-info', { siteId: id })
            .then((data) => {
                setDomainData(data.data)
            })

    }, 30000, { leading: true });

    useEffect(() => {
        if (!showed) return;
        debouncedRequest();
    }, [id, showed])

    const popover = (
        <Popover id="popover-block">
            <Popover.Header as="h3">Информация о домене</Popover.Header>
            <Popover.Body>
                {domainData ?
                            <div ref={ref}>
                                <div><strong>Домен:</strong> {domainName}</div>
                                <div><strong>Cloudflare (профиль):</strong>
                                    <div>{domainData?.cloudflare ? domainData?.cloudflare : 'Не доступен до переезда'}</div>
                                </div>
                                <div><strong>Регистратор (профиль):</strong>
                                    <div>{domainData?.registrar ? domainData?.registrar : 'Не доступен до переезда'}</div>
                                </div>
                                <div><strong>Сервера имен:</strong>
                                    <div>{domainData?.nameServers.length > 0 ? domainData?.nameServers?.join(' ') : '-'}</div>
                                </div>
                                <div><strong>Дата создания:</strong>
                                    <div>{domainData?.creationDate ? formatDateTime(domainData?.creationDate) : '-'}</div>
                                </div>
                                <div><strong>Окончание регистрации:</strong>
                                    <div>{domainData?.expirationDate ? formatDateTime(domainData?.expirationDate) : '-'}</div>
                                </div>
                            </div>
                : 'Загрузка...'
                }
            </Popover.Body>
        </Popover>
    );

    return <OverlayTrigger trigger="click" show={showed} onToggle={() => setShowed(!showed)} placement="right" overlay={popover}>
        <div>{domainName}</div>
    </OverlayTrigger>
}
