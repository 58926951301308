import {useCallback, useEffect, useState} from "react";
import ApiManager from "../api";
import {Card} from "react-bootstrap";
import DataGridWrapper from "../components/DataGridWrapper";

function DashboardCloudflare() {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const data = useCallback(async () => {
        const apiManager = new ApiManager();
        return await apiManager.request('dashboard.cloudflare');
    }, [])

    useEffect(() => {
        data().then(({data}) => {
            const rows = []
            const columns = [
                {
                    key: 'email',
                    name: 'Аккаунт/Оффер',
                    frozen: true
                },
            ];

            data.categories.forEach((category) => columns.push({
                key: category.title + category.id,
                name: category.title
            }));

            Object.keys(data.domainQuantity).forEach(
                (email) => {
                    const row = {
                        email
                    }

                    data.domainQuantity[email].forEach((item) => row[item.category.title + item.category.id] = item.totalCount)

                    rows.push(row)
                }
            )

            setColumns(columns)
            setRows(rows)
        })

    }, [data])


    return (
        <Card>
            <Card.Header>Статистика по аккаунтам CloudFlare</Card.Header>
            <Card.Body>
                <Card.Title>Количество доменов на аккаунте</Card.Title>
                <Card.Text>
                    <DataGridWrapper
                        columns={columns}
                        rows={rows}
                    />
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default DashboardCloudflare