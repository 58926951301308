import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ButtonOverlay({ children, tooltip, placement }) {
    return (
        <OverlayTrigger
            placement={placement && "top"}
            overlay={<Tooltip>{tooltip}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}