import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SitesAddForm() {
    const { values, handleChange, errors } = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Название</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    placeholder="Например: Оффер 1"
                    isInvalid={ !!errors.title }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="apiKey">
                <Form.Label>Примечание</Form.Label>
                <Form.Control
                    as="textarea"
                    name="note"
                    rows={5}
                    value={ values.note }
                    onChange={ handleChange }
                    type="text"
                    isInvalid={ !!errors.note }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.note }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
