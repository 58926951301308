import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import BaseButton from "./Button.js"

function ButtonDelete({ onClick }) {
    return (
        <BaseButton placement="top" tooltip="Удалить">
            <Button size="sm" onClick={onClick} variant="outline-danger">
                <FontAwesomeIcon icon={faTrash}/>
            </Button>
        </BaseButton>
    )
}

export default ButtonDelete;
