import {ButtonGroup, Card} from "react-bootstrap";
import {ApiAddButton, ApiConfirmModalDialog, ApiPaginator, ApiWrapper} from "../../components/ApiWrapper";
import {formatDateTime} from "../../utils/helper";
import styles from "../Sites/SiteCellBackground.module.css";
import ButtonEdit from "../../components/buttons/ButtonEdit";
import ButtonDelete from "../../components/buttons/ButtonDelete";
import DataGridWrapper from "../../components/DataGridWrapper";
import ApiModalFormWrapper from "../../components/ApiWrapper/ApiModalFormWrapper";
import UserListForm, {validationSchema} from "./UserListForm";
import {useContext, useEffect, useState} from "react";
import ApiManager from "../../api";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";

export default function UserList() {
    const {route} = useContext(ApiWrapperContext);
    const [userMap, setUserMap] = useState(false);

    useEffect(() => {
        const apiManager = new ApiManager();

        apiManager
            .request('user.maps')
            .then(data => setUserMap(data?.map))

    }, [route])

    function getColumns({handleEdit, handleConfirmDelete}) {
        return [
            {
                key: 'id',
                name: 'ID'
            },
            {
                key: 'email',
                name: 'Email'
            },
            {
                key: 'userName',
                name: 'Логин'
            },
            {
                key: 'role',
                name: 'Роль',
                renderCell({row}) {
                    return userMap?.roles[row?.role];
                }
            },
            {
                key: 'status',
                name: 'Статус',
                renderCell({row}) {
                    return userMap?.statuses[row?.status];
                }
            },
            {
                key: 'createdAt',
                name: 'Дата регистрации',
                renderCell({row}) {
                    return formatDateTime(row.createdAt)
                }
            },
            {
                key: 'action',
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return (
                        <ButtonGroup className="mb-2">
                            <ButtonEdit onClick={() => handleEdit(row, 'modalWithForm')}/>
                            <ButtonDelete onClick={() => handleConfirmDelete(row, 'modalConfirm')}/>
                        </ButtonGroup>
                    )
                }
            }
        ];
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex justify-content-between">
                        Список пользователей
                        <ApiAddButton
                            size="sm"
                            variant="outline-dark"
                            modalId="modalWithForm">
                            Добавить
                        </ApiAddButton>
                    </div>
                </Card.Title>
                <Card.Text>
                    {userMap ? (
                        <ApiWrapper>
                            {(props) => <DataGridWrapper
                                defaultColDef={{flex: 1}}
                                columns={getColumns(props)}
                                rows={props.fetchedData.items}
                            />}
                        </ApiWrapper>
                    ) : 'Загрузка...'}

                </Card.Text>
                <ApiModalFormWrapper
                    windowId="modalWithForm"
                    validationSchema={validationSchema}
                    title="Изменение учетных данных">
                    <UserListForm userMap={userMap}/>
                </ApiModalFormWrapper>
                <ApiConfirmModalDialog windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalDialog>
                <ApiPaginator size="sm"/>
            </Card.Body>
        </Card>
    )
}