import {useReducer, createContext} from "react";
import modalReducer from "../reducer/modalReducer.js"

export const ModalContext = createContext();

function ModalContextProvider(props) {

    const [state, dispatch] = useReducer(modalReducer, {
        windowId: null,
        isOpen: false,
        payload: {}
    })

    function closeModal(windowId, payload = {}) {
        dispatch({
            type: 'modal',
            windowId: windowId,
            isOpen: false,
            payload: {...state.payload, ...payload}
        });
    }

    function openModal(windowId, payload = {}) {
        dispatch({
            type: 'modal',
            windowId: windowId,
            isOpen: true,
            payload: {...state.payload, ...payload}
        });
    }

    const contextValues = {
        closeModal,
        openModal,
        isOpen: state.isOpen,
        windowId: state.windowId,
        payload: state.payload,
    }

    return (
        <ModalContext.Provider value={contextValues}>
            {props.children}
        </ModalContext.Provider>
    );
}

export default ModalContextProvider;