import DashboardCloudflare from "./DashboardCloudflare";
import DashboardExpiresDomain from "./DashboardExpiresDomain";
import ApiWrapperContextProvider from "../context/ApiWrapperContext";
import DashboardRknCheckInfo from "./DashboardRknCheckInfo";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

function Dashboard() {

    const {currentUser} = useContext(AuthContext)

    return (
        <>
            {currentUser.role === 'admin' && <DashboardRknCheckInfo/>}
            <DashboardCloudflare/>
            <ApiWrapperContextProvider route='dashboard.expires-domains'>
                <DashboardExpiresDomain/>
            </ApiWrapperContextProvider>
        </>
    )
}

export default Dashboard;
