import {Tab, Col, Row, Card} from "react-bootstrap";
import SitesNav from "./SitesNav";
import {useCallback, useEffect, useState} from "react";
import 'react-data-grid/lib/styles.css';
import ApiManager from "../../api";
import styles from "./SiteCellBackground.module.css";
import {useNavigate} from "react-router-dom";
import ButtonTable from "./components/ButtonTable";
import Calendar from "../../components/Calendar";
import {getCellStyleForValue} from "../../utils/helper";
import {DataGridMemorySortable} from "../../components/DataGridSortable";

function getComparator(sortColumn) {
    if (sortColumn !== 'keyword') {
        return (a, b) => {
            return a[sortColumn].sortPosition - b[sortColumn].sortPosition;
        };
    }
}

export default function SitesPosition() {
    const [currentCategory, setCurrentCategory] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date())
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const data = useCallback(async () => {
        const apiManager = new ApiManager();
        return await apiManager.request('stat.category.position', {
            categoryId: currentCategory.id,
            currentDate: currentDate.toDateString()
        });
    }, [currentCategory.id, currentDate])

    useEffect(() => {
        if (!currentCategory) return;

        data().then((data) => {
            const rows = []
            const columns = [
                {
                    key: 'keyword',
                    name: 'Ключевое слово',
                    frozen: true,
                    sortable: false
                }
            ];

            data.domains.forEach((domain, index) => columns.push({
                key: domain + index,
                name: domain,
                renderCell: ({row}) => {
                    const cellValue = row[domain + index];
                    const diffPosition = cellValue?.currentPosition - cellValue?.previousPosition;

                    if (cellValue?.previousPosition === 0) {
                        return <div>{cellValue?.currentPosition}</div>
                    } else if (diffPosition > 0) {
                        return <div>{cellValue?.currentPosition} <i className="text-danger">+{diffPosition}</i></div>
                    } else if (diffPosition < 0) {
                        return <div>{cellValue?.currentPosition} <i className="text-success">{diffPosition}</i></div>
                    } else if (cellValue?.currentPosition === 0 && cellValue?.previousPosition === 0) {
                        return <div>-</div>
                    }

                    return <div>{cellValue?.currentPosition}</div>
                },
                cellClass: (row) => {
                    const cellValue = row[domain + index];
                    return getCellStyleForValue(cellValue?.currentPosition, styles);
                },
            }));

            data.statistics.forEach(keywordPosition => {
                const row = {
                    keyword: keywordPosition.query
                }

                keywordPosition.positions.forEach((position, index) => {
                    // делаем нули всегда внизу при сортировке
                    position.sortPosition = position.currentPosition === 0 ? 999999 : position.currentPosition;
                    row[position.domain + index] = position
                })

                rows.push(row)
            })

            setColumns(columns)
            setRows(rows)
        })

    }, [data])

    return (
        <Tab.Container id="left-tabs-example" activeKey={`nav_${currentCategory?.id}`}>
            <Row>
                <Col xs={12} md={2}>
                    <SitesNav onNavSelect={(nav) => setCurrentCategory(nav)}/>
                </Col>
                <Col xs={12} md={10}>
                    <Tab.Content>
                        <Card>
                            <Card.Body>


                                <Card.Title>
                                    <div className="d-flex justify-content-between">
                                    Позиции сайтов за {currentDate.toLocaleDateString()}
                                        <Calendar
                                            onChange={(value) => setCurrentDate(value)}
                                            isHidden={showCalendar}
                                        />

                                        <ButtonTable
                                            tooltip="Показать календарь"
                                            onClick={() => setShowCalendar(!showCalendar)}
                                        />
                                    </div>
                                </Card.Title>

                                {!currentCategory ? 'Загрузка...' :
                                    <Card.Text>
                                        <Tab.Pane eventKey={`nav_${currentCategory.id}`}>
                                         <DataGridMemorySortable
                                            setComparator={getComparator}
                                            defaultColDef={{flex: 1}}
                                            className="fill-grid"
                                            columns={columns}
                                            rows={rows}
                                            defaultColumnOptions={{
                                                sortable: true
                                            }}
                                            onCellClick={({column}) => {
                                                if (column.key !== 'keyword') {
                                                    navigate(`/sites/${column.name}/positions`);
                                                }
                                            }}
                                        />
                                        </Tab.Pane>
                                    </Card.Text>

                                }
                            </Card.Body>
                        </Card>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>)
}