import registrars from "./registrars.json"
import {Form} from "react-bootstrap";
import {useFormikContext} from "formik";


import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Поле обязательно для заполнения.'),
    registrarId: Yup.string().required('Поле обязательно для заполнения.'),
    login: Yup.string().required('Поле обязательно для заполнения.'),
    password: Yup.string().required('Поле обязательно для заполнения.')
})

export default function RegistrarsForm() {
    const {values, handleChange, errors} = useFormikContext();

    const registrarId = Object.keys(registrars).shift();
    return (
        <Form>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Название профиля</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    placeholder="Например: Профиль 1"
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="registrarId">
                <Form.Label>Регистратор</Form.Label>
                <Form.Select
                    name="registrarId"
                    value={values.registrarId || registrarId}
                    onChange={ handleChange }
                    isInvalid={ !!errors.registrarId }
                    aria-label="Выберите регистратора"
                >
                    {Object.keys(registrars).map(id => <option value={id}>{registrars[id].title}</option>)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.registrarId }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>{registrars[values.registrarId || registrarId].formFields.login}</Form.Label>
                <Form.Control
                    type="text"
                    name="login"
                    value={values.login}
                    isInvalid={ !!errors.login }
                    onChange={ handleChange }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.login }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="apiKey">
                <Form.Label>{registrars[values.registrarId || registrarId].formFields.password}</Form.Label>
                <Form.Control
                    type="text"
                    name="password"
                    value={values.password}
                    isInvalid={ !!errors.password }
                    onChange={ handleChange }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.password }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}
