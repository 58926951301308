export default function modalReducer(state, action) {
    switch (action.type) {
        case 'modal': {
            return {
                ...state,
                isOpen: action.isOpen,
                windowId: action.windowId,
                payload: action.payload
            }
        }
        default:
            return state;
    }
}