import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SitesListManualTransferForm() {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="domain">
                <Form.Label>Введите новый домен для переезда</Form.Label>
                <Form.Control
                    name="domain"
                    type="text"
                    value={values.domain}
                    onChange={handleChange}
                    placeholder="Например: example.com"
                    isInvalid={!!errors.domain}
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    {errors.domain}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
