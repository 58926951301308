import {Button, Modal} from "react-bootstrap";
import {useContext} from "react";
import {ModalContext} from "../../../context/ModalContext";
import '../DisableBorder.css';
import SitesListHistory from "./SitesListHistory";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";

export default function SitesListHistoryModal({windowId, siteId, ...rest}) {
    const {isOpen, closeModal, windowId: currentWindowId} = useContext(ModalContext);
    const onClose = () => closeModal(windowId);

    return (
        <Modal {...rest}
               show={currentWindowId === windowId ? isOpen : false}
               onHide={() => closeModal(windowId)}
               fullscreen={true}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    История переездов
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApiWrapperContextProvider route='sites.site.history' routeParams={{siteId}}>
                    <SitesListHistory siteId={siteId}/>
                </ApiWrapperContextProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
}
