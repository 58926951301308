import {useContext} from "react";
import {FilterContext} from "../../context/DataGridFilterContext";

export default function FilterRenderer({tabIndex, column, children}) {
    const {isFiltersEnabled, filters} = useContext(FilterContext);
    return (
        <>
            <div>{column.name}</div>
            {isFiltersEnabled && <div>{children({tabIndex, filters})}</div>}
        </>
    );
}