import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import ApiManager from "../../../api";
import {useContext} from "react";
import {ModalContext} from "../../../context/ModalContext";
import useDebounce from "../../../utils/debounce";

function RuleItem({place, rule, group, onMoveDown, onMoveUp}) {

    const {openModal} = useContext(ModalContext);

    const handleConfirmDelete = () => openModal('modalConfirmRule', {
        ruleId: rule.id,
        groupId: group.id,
        isDeleted: false
    })

    const handleHighPriority = () => {
        new ApiManager()
            .update('cloudflare.waf.rule.change-priority', { direction: 'higher'}, {
                ruleId: rule.id
            })
            .then(() => onMoveUp())
    }

    const handleLowPriority = () => {
        new ApiManager()
            .update('cloudflare.waf.rule.change-priority', { direction: 'lower'}, {
                ruleId: rule.id
            })
            .then(() => onMoveDown())
    }

    const handleExpressionChange = useDebounce((e) => {
        new ApiManager()
            .update('cloudflare.waf.rule', { expression: e.target.value}, {
                groupId: group.id,
                id: rule.id
            })
    }, 2000, { leading: true })

    const handleDescriptionChange = useDebounce((e) => {
        new ApiManager()
            .update('cloudflare.waf.rule', { description: e.target.value}, {
                groupId: group.id,
                id: rule.id
            })
    }, 1000, { leading: true })

    const handleActionChange = (e) => {
        new ApiManager()
            .update('cloudflare.waf.rule', { action: e.target.value}, {
                groupId: group.id,
                id: rule.id
            });
    }

    return <div className="d-flex justify-content-around">
        <div className="col-10">
            <div className="d-flex align-items-center justify-content-center">
                <span className="m-2">{place}.</span>
                <div className="m-2" style={{'width': '100%'}}>
                    <input type="text"
                           onKeyUp={handleDescriptionChange}
                           className="form-control mb-1"
                           placeholder="Описание 255 символов макс."
                           defaultValue={rule.description}
                    />
                    <textarea
                        onKeyUp={handleExpressionChange}
                        className="form-control"
                        placeholder="Введите правило"
                        style={{'minHeight': '100px'}}
                        defaultValue={rule.expression}
                    />
                </div>
            </div>
        </div>
        <div className="col-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <select className="form-select mt-2"
                        aria-label="Выберите действие"
                        onChange={handleActionChange}
                        defaultValue={rule.action}
                >
                    <option value="block">Block</option>
                    <option value="skip">Skip</option>
                </select>
                <div className="d-flex m-2">
                    <ButtonOverlay tooltip="Понизить приоритет">
                        <Button onClick={handleLowPriority} size="sm" variant="outline-primary" className="m-1">
                            <FontAwesomeIcon icon={faArrowDown}/>
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay tooltip="Повысить приоритет">
                        <Button onClick={handleHighPriority} size="sm" variant="outline-primary" className="m-1">
                            <FontAwesomeIcon icon={faArrowUp}/>
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay className="flex-grow-1" tooltip="Удалить">
                        <Button onClick={handleConfirmDelete} size="sm" variant="danger" className="m-1">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </ButtonOverlay>
                </div>
            </div>
        </div>
    </div>
}

export default RuleItem