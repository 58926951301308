import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import RuleItem from "./RuleItem";
import {useContext, useEffect, useState} from "react";
import ApiManager from "../../../api";
import {ModalContext} from "../../../context/ModalContext";
import useDebounce from "../../../utils/debounce";

function GroupItem({group}) {
    const [showed, setShowed] = useState(false);
    const [rules, setRules] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);

    const {openModal, payload} = useContext(ModalContext);

    const handleAddRule = () => {
        new ApiManager()
            .create('cloudflare.waf.rule', {}, {
                groupId: group.id
            })
            .then(() => setLastUpdated(Date.now()))
    }

    const handleUpdate = () => setLastUpdated(Date.now())

    const handleConfirmDelete = () => {
        openModal('modalConfirmGroup', {
            groupId: group.id,
            isDeleted: false
        });
    }

    const handleChangeName = useDebounce((e) => {
        new ApiManager()
            .update('cloudflare.waf.group', {
                name: e.target.value
            }, {
                id: group.id
            });
    }, 1000, { leading: true })

    const handleChangeDescription = useDebounce((e) => {
        new ApiManager()
            .update('cloudflare.waf.group', {
                description: e.target.value
            }, {
                id: group.id
            });
    }, 1000, { leading: true })

    useEffect(() => {

        if (!showed) {
            setRules([]);
            return;
        }

        new ApiManager()
            .request('cloudflare.waf.rule', {
                groupId: group.id
            })
            .then(data => {
                setRules(data.data)
            })

    }, [showed, lastUpdated, group.id, payload.isDeleted])

    return <Card className="mt-2">
        <Card.Header className="d-flex flex-row align-items-center">
            <div className="flex-grow-0">
                <Button size="sm" variant="outline-dark" className="m-1" onClick={() => setShowed(!showed)}>
                    <FontAwesomeIcon icon={showed ? faCaretUp : faCaretDown}/>
                </Button>
            </div>
            <div className="flex-grow-1">
                <input
                    className="form-control"
                    type="text"
                    onChange={handleChangeName}
                    placeholder="Название группы правил 255 символов макс"
                    defaultValue={group.name}
                />
                <input
                    className="form-control"
                    type="text"
                    placeholder="Описание группы правил 255 символов макс"
                    onKeyUp={handleChangeDescription}
                    defaultValue={group.description}
                />
            </div>
            <div>
                <Button size="sm" variant="outline-danger" className="m-1" onClick={handleConfirmDelete}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </div>
        </Card.Header>

        {showed &&
        <Card.Body>
            <ButtonOverlay tooltip="Новое правило">
                <Button size="sm" onClick={handleAddRule} variant="outline-success" className="m-1">
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </ButtonOverlay>
            {rules.map((rule, index) => <RuleItem
                place={index + 1}
                rule={rule}
                group={group}
                key={index + 1}
                onMoveUp={handleUpdate}
                onMoveDown={handleUpdate}
                onDelete={handleUpdate}
            />)}
        </Card.Body>
        }
    </Card>
}

export default GroupItem