import {Card, Button, Col, Row} from 'react-bootstrap';
import {Formik} from "formik";
import SettingsForm, {validationSchema} from "./SettingsForm.js";
import ApiManager from "../../api"
import {useCallback, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import AlertDismissible from "../../components/AlertDismissible";

function Settings() {
    const {getToken, endpointUrl} = useContext(AuthContext)
    const [initialFieldValues, setInitialFieldValues] = useState({});
    const [isSaved, setIsSaved] = useState(false)

    const apiManager = useCallback(
        () => (new ApiManager(getToken(), endpointUrl)),
        [getToken]
    )

    function onSubmit(values) {

        const data = {
            telegramBot: {
                apiKey: values.telegramBotApiKey
            },
            crawler: {
                captchaSolverApiKey: values.crawlerCaptchaSolverApiKey,
                launchInterval: values.crawlerLaunchInterval
            },
            rknCheck: {
                mode: values.rknCheckMode
            }
        }

        apiManager()
            .update('settings', data)
            .then(() => {
                setIsSaved(true)
                setTimeout(() => setIsSaved(false), 15000)
            })
    }
    //
    useEffect(() => {
        const formFields = Object.keys(validationSchema.json().fields).map(field => [field, ""]);
        apiManager()
            .request('settings')
            .then((values) => {

            if (values) {
                setInitialFieldValues({
                    telegramBotApiKey: values.telegramBot.apiKey,
                    crawlerCaptchaSolverApiKey: values.crawler.captchaSolverApiKey,
                    crawlerLaunchInterval: values.crawler.launchInterval,
                    crawlerLaunchIntervalList: values.crawler.launchIntervalList,
                    rknCheckMode: values.rknCheck.mode,
                    rknCheckModeList: values.rknCheck.modeList
                })
            } else {
                setInitialFieldValues(Object.fromEntries(formFields));
            }
        })
    }, [isSaved])

    return (
        <Card>
            <Card.Body>
                <AlertDismissible
                    show={isSaved}
                    onClose={() => setIsSaved(false)}
                    variant="success">
                    Настройки сохранены
                </AlertDismissible>
                <Card.Title>Настройки</Card.Title>
                <Card.Text>
                    <Row>
                        <Col sm={6}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialFieldValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({handleSubmit}) => (
                                    <>
                                        <SettingsForm/>
                                        <Button onClick={handleSubmit} variant="primary">Сохранить</Button>
                                    </>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Card.Text>

            </Card.Body>
        </Card>
    )
}

export default Settings;
