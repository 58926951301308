import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Поле обязательно для заполнения.'),
    registrarId: Yup.string().required('Поле обязательно для заполнения.'),
    cloudflareId: Yup.string().required('Поле обязательно для заполнения.'),
})

export default function GlueDomainsForm({cloudflareProfile, registrars, registrarProfile}) {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="domain">
                <Form.Label>Домен или домены списком (Каждый домен с новой строки).</Form.Label>
                <Form.Control
                    name="domain"
                    as="textarea"
                    type="text"
                    value={values.domain}
                    onChange={handleChange}
                    placeholder={`example1.com\nexample2.com\nexample3.com`}
                    isInvalid={!!errors.domain}
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    {errors.domain}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="registrarId">
                <Form.Label>Регистратор</Form.Label>
                <Form.Select
                    name="registrarId"
                    value={values.registrarId}
                    onChange={handleChange}
                    isInvalid={!!errors.registrarId}
                    aria-label="Выберите регистратора"
                >
                    <option value="">Выберите профиль регистратора</option>
                    {registrarProfile.length > 0 ? registrarProfile.map(registrar => (
                        <option key={registrar.id}
                                value={registrar.id}>{registrar.title} ({registrars[registrar.registrarId].title})</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.registrarId}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailId">
                <Form.Label>Аккаунт CloudFlare</Form.Label>
                <Form.Select
                    name="cloudflareId"
                    value={values.cloudflareId}
                    onChange={handleChange}
                    isInvalid={!!errors.cloudflareId}
                    aria-label="Выберите аккаунт CloudFlare"
                >
                    <option value="">Выберите профиль CloudFlare</option>
                    {cloudflareProfile.profiles.length > 0 ? cloudflareProfile.profiles.map(cloudflare => (
                        <option key={cloudflare.id} value={cloudflare.id}>{cloudflare.title} ({cloudflare.email})  домены: {(typeof cloudflare?.domainsWithCategory !== "undefined") ? cloudflare.domainsWithCategory + '/': ''}{cloudflare.domains}</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.cloudflareId}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}
