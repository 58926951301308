import ApiWrapperContextProvider from "../../context/ApiWrapperContext";
import ModalContextProvider from "../../context/ModalContext";
import {ButtonGroup, Card, Table} from "react-bootstrap";
import {ApiAddButton, ApiConfirmModalDialog, ApiPaginator, ApiWrapper} from "../../components/ApiWrapper";
import ButtonEdit from "../../components/buttons/ButtonEdit";
import ButtonDelete from "../../components/buttons/ButtonDelete";
import RegistrarsForm, {validationSchema} from "./RegistrarsForm";
import {truncateString} from "../../utils/helper";
import registrars from  "./registrars.json"
import ApiModalFormWrapper from "../../components/ApiWrapper/ApiModalFormWrapper";

function Registrars() {
    return (
        <ApiWrapperContextProvider route='registrar'>
            <ModalContextProvider>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className="d-flex justify-content-between">
                                Профили Регистраторов
                                <ApiAddButton size="sm" variant="outline-dark" modalId="modalWithForm">Добавить</ApiAddButton>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            <Table responsive borderless size="sm">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Профиль</th>
                                    <th>Регистратор</th>
                                    <th>Логин</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ApiWrapper>
                                    {({fetchedData, handleEdit, handleConfirmDelete}) => fetchedData?.items?.map(item => (
                                        <tr key={item.id}>
                                            <td>
                                                {item.id}
                                            </td>
                                            <td>
                                                {truncateString(item.title, 20)}
                                            </td>
                                            <td>
                                                {registrars[item.registrarId].title}
                                            </td>
                                            <td>
                                                {truncateString(item.login, 20)}
                                            </td>
                                            <td width="20">
                                                <ButtonGroup className="mb-2">
                                                    <ButtonEdit onClick={() => handleEdit(item, 'modalWithForm')}/>
                                                    <ButtonDelete onClick={() => handleConfirmDelete(item, 'modalConfirm')}/>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ApiWrapper>
                                </tbody>
                            </Table>
                            <ApiPaginator/>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <ApiModalFormWrapper windowId="modalWithForm" validationSchema={validationSchema} title="Изменение учетных данных">
                    <RegistrarsForm/>
                </ApiModalFormWrapper>
                <ApiConfirmModalDialog windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalDialog>
            </ModalContextProvider>
        </ApiWrapperContextProvider>
    )
}

export default Registrars;
