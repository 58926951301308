import {Form} from "react-bootstrap";
import {useState} from "react";

function SwitchableInput({formInput, formSelect, mainTitle, onSwitch}) {
    const [isManual, setIsManual] = useState(false)

    return <Form.Group className="mb-3">
        <Form.Label style={{width: '100%'}}>
            <div className="d-flex justify-content-between">
                {mainTitle}
                <Form.Check
                    type="switch"
                    label="Ручной ввод"
                    checked={isManual}
                    onChange={(event) => {
                        const checked = event.target.checked;
                        onSwitch(checked)
                        setIsManual(checked)
                    }}
                />
            </div>
        </Form.Label>
        {isManual ? formInput : formSelect}
    </Form.Group>
}

export default SwitchableInput