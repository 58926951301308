import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTractor} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../../../components/buttons/Button.js"

function ButtonTransfer({onClick}) {
    return (
        <BaseButton placement="top" tooltip="Ручной переезд">
            <Button size="sm" onClick={onClick} variant="outline-info">
                <FontAwesomeIcon icon={faTractor} />
            </Button>
        </BaseButton>
    );
}

export default ButtonTransfer;
