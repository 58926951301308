import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb} from "@fortawesome/free-solid-svg-icons";

export default function SitesNote({children, ...rest}) {
    return (
        <Alert variant="info" {...rest}>
            <Alert.Heading> <FontAwesomeIcon icon={faLightbulb}/> Примечание</Alert.Heading>
            <p>{ children }</p>
        </Alert>
    )
}