import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Button({ children, tooltip, placement }) {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip>{tooltip}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}

export default Button;