import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTable} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

export default function ButtonTable({onClick, tooltip}) {
    return (
        <ButtonOverlay tooltip={tooltip}>
            <Button size="sm" onClick={onClick} variant="outline-primary">
                <FontAwesomeIcon icon={faTable}/>
            </Button>
        </ButtonOverlay>
    )
}