import {Button, Form, Col} from "react-bootstrap";
import {Formik} from "formik";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import ApiManager from "../../api"
import AlertDismissible from "../../components/AlertDismissible";

export default function UserSettingsStatForm() {
    const [initialFieldValues, setInitialFieldValues] = useState({});
    const [isSaved, setIsSaved] = useState(false)

    const validationSchema = Yup.object().shape({
        userId: Yup.string().required('Поле обязательно для заполнения.'),
        apiKey: Yup.string().required('Поле обязательно для заполнения.')
    });

    useEffect(() => {
        new ApiManager()
            .request('user-settings')
            .then((data) => {
                const settings = {};
                for (const groupKey of Object.keys(data.data.settings)) {
                    const [group, key] = groupKey.split('.');
                    if (group === 'xmlfeed') {
                        settings[key] = data.data.settings[groupKey];
                    }
                }

                setInitialFieldValues(settings)
            })
    }, []);

    function onSubmit(values) {

        let settings = {};

        for (const key of Object.keys(values)) {
            settings[`xmlfeed.${key}`] = values[key]
        }

        new ApiManager()
            .create('user-settings', {settings})
            .then(() => {
                setIsSaved(true)
                setTimeout(() => setIsSaved(false), 15000)
            })
    }

    return (
        <>
            <AlertDismissible
                show={isSaved}
                onClose={() => setIsSaved(false)}
                variant="success">
                Настройки сохранены
            </AlertDismissible>
            <Formik
                enableReinitialize={true}
                initialValues={initialFieldValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({errors, handleChange, values, handleSubmit, setFieldValue}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group as={Col} md="2" className="mb-3" controlId="userId">
                            <Form.Label>Id пользователя</Form.Label>
                            <Form.Control
                                type="text"
                                name="userId"
                                value={values.userId}
                                onChange={handleChange}
                                isInvalid={!!errors.userId}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.userId}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="2" className="mb-3" controlId="apiKey">
                            <Form.Label>Ключ api</Form.Label>
                            <Form.Control
                                type="text"
                                name="apiKey"
                                value={values.apiKey}
                                onChange={handleChange}
                                isInvalid={!!errors.apiKey}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.apiKey}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="isActive">
                            <Form.Check
                                type="switch"
                                name="isActive"
                                label="Включить"
                                checked={values.isActive === '1'}
                                onChange={(event) => {
                                    setFieldValue('isActive', event.target.checked ? '1' : '0')
                                }}
                                isInvalid={!!errors.isActive}
                            />
                        </Form.Group>
                        <Button type="submit" variant="primary">Сохранить</Button>
                    </Form>
                )}
            </Formik>
        </>
    )
}