import {NavDropdown as BootstrapNavDropdown} from "react-bootstrap";

function NavDropdown({navItems, currentNavLink, children, ...rest}) {

    const dropDownItem = (eventKey, title) => <BootstrapNavDropdown.Item key={eventKey} eventKey={eventKey}>{title}</BootstrapNavDropdown.Item>

    return (
        <BootstrapNavDropdown active={Object.keys(navItems).includes(currentNavLink)} {...rest}>
            {Object.keys(navItems).map(key => children ? children(key, navItems[key]) : dropDownItem(key, navItems[key]))}
        </BootstrapNavDropdown>
    )
}

export default NavDropdown