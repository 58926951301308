import {useMemo, useState} from "react";
import DataGridWrapper from "../DataGridWrapper";

export function MemorySortableWrapper({style, rows, setComparator, defaultColDef, columns, ...rest})
{
    const [sortColumns, setSortColumns] = useState([]);

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;

        const columnNames = columns.map(({key}) => key);
        const sortColumnNames = sortColumns.map(({columnKey}) => columnKey);

        for (const sortColumnName of sortColumnNames) {
            const result = columnNames.find((columnName) => columnName === sortColumnName);
            if (result === undefined) {
                return rows;
            }
        }

        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = setComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, columns, sortColumns, setComparator]);

    return <DataGridWrapper
        defaultColumnOptions={{
            sortable: false
        }}
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        columns={columns.map(column => ({...defaultColDef, ...column}))}
        {...rest}
    />
}