import ModalWithForm from "../modal/ModalWithForm";
import ApiFormValidation from "./ApiFormValidation";
import {useContext} from "react";
import {ModalContext} from "../../context/ModalContext";

export default function ApiModalFormWrapper({validationSchema, windowId, title, children, ...rest}) {

    const {closeModal} = useContext(ModalContext);

    const handleAfterSubmit = () => {
        closeModal(windowId)
    }

    return (
        <ApiFormValidation onAfterSubmit={handleAfterSubmit} validationSchema={validationSchema} {...rest}>
            <ModalWithForm windowId={windowId} title={title} {...rest}>
                {children}
            </ModalWithForm>
        </ApiFormValidation>
    )
}