import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    keyword: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SitesKeywordAddForm() {
    const {values, handleChange, errors, initialValues} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="keyword">
                {initialValues['keyword'] === '' ? (
                    <>
                        <Form.Label>Ключевые слова (каждое с новой строки)</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="keyword"
                            type="text"
                            value={values.keyword}
                            onChange={handleChange}
                            placeholder={`леонбетс зеркало\nплей фортуна`}
                            isInvalid={!!errors.keyword}
                            autoFocus
                        />
                    </>
                ) : (
                    <>
                        <Form.Label>Ключевое слово</Form.Label>
                        <Form.Control
                            name="keyword"
                            type="text"
                            value={values.keyword}
                            onChange={handleChange}
                            isInvalid={!!errors.keyword}
                            autoFocus
                        />
                    </>
                )}
                <Form.Control.Feedback type="invalid">
                    {errors.keyword}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}