import {useContext, useEffect} from 'react';
import axios from './api/axios';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./context/AuthContext";

const AxiosErrorHandler = ({children}) => {

    const {handleLogout, isAuthenticated} = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        // Request interceptor
        /*const requestInterceptor = axios.interceptors.request.use((request) => {
            console.debug(request)
            if (request.response?.status) {
                switch (request.response.status) {
                    case 403:
                        navigate('/')
                    break;
                }
            }
            // Do something here with request if you need to
            return request;
        });*/

        // Response interceptor
        const responseInterceptor = axios.interceptors.response.use((response) => {
            // Handle response here

            return response;
        }, (error) => {
            // Handle errors here
            if (error.response?.status) {
                switch (error.response.status) {
                    case 401:
                        handleLogout()
                        break;
                    case 403:
                        // Handle Unauthorized here
                        navigate('/')
                        break;
                    case 422:
                        return Promise.reject(error)
                    // ... And so on
                }
            }

            return error;
        });

        return () => {
            // Remove handlers here
            //axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [isAuthenticated]);

    return children;
};

export default AxiosErrorHandler;