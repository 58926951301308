import { Button, Modal } from "react-bootstrap";
import { useContext } from "react"
import { ModalContext} from "../../context/ModalContext.js";

function ModalDialog({ title, children, windowId, onConfirm }) {

    const { isOpen, closeModal, windowId: currentWindowId, payload } = useContext(ModalContext);
    const onClose = () => closeModal(windowId);

    return (
        <Modal centered show={ currentWindowId === windowId ? isOpen : false } onHide={ onClose }>
            <Modal.Header closeButton>
                <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                { children }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={ onClose }>Нет</Button>
                <Button variant="danger" onClick={ () => onConfirm(payload) }>Да</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDialog;