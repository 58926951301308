import { useFormikContext } from "formik";
import {Form} from "react-bootstrap";
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required()
})

export default function GroupForm() {
    const { values, handleChange, errors } = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Название группы</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
