import {Alert, OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShield} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {useClickAway} from "@uidotdev/usehooks";
import ApiManager from "../../../api";

function ApplyWafRules({row: {id, hasWafRules, wafGroupId, cloudflareId}, groups, onSuccess}) {

    const [showed, setShowed] = useState(false);
    const [currentGroup, setCurrentGroup] = useState(null);

    const ref = useClickAway(() => {
        setShowed(false);
        setCurrentGroup(null);
    });

    const isGroupSelected = () => {
        const group = parseInt(currentGroup);
        return !isNaN(group);
    }

    const handleDetachGroup = () => {
        new ApiManager()
            .delete('sites.waf-group', {}, {
                'id': wafGroupId,
                'siteId': id
            })
            .then((data) => {
                onSuccess(data);
            })
    }

    const handleAttachGroup = () => {

        const group = parseInt(currentGroup);

        new ApiManager()
            .create('sites.waf-group', {'groupId': group}, {
                'siteId': id
            })
            .then((data) => {
                onSuccess(data);
            })
    }

    const popover = (
            <Popover id="popover-block">
                <Popover.Header as="h3">Группа правил</Popover.Header>
                <Popover.Body>
                    <div ref={ref}>
                        {cloudflareId ? <div>
                                {wafGroupId ?
                                    <div>
                                        <p>Группа <strong>{groups.find(group => group.id === wafGroupId)?.name}</strong></p>
                                        <button
                                            onClick={handleDetachGroup}
                                            className="btn btn-outline-danger"
                                            type="button">
                                            Удалить привязку
                                        </button>
                                    </div> :
                                    <div className="input-group mb-3">
                                        <select
                                            className="form-select"
                                            aria-label="Выбрать"
                                            onChange={(e) => setCurrentGroup(e.target.value)}
                                            defaultValue={wafGroupId}
                                        >
                                            <option>Выбрать</option>
                                            {groups.filter(group => group?.name?.length > 0).map(group => (
                                                <option key={group.id} value={group.id}>{group.name}</option>))}
                                        </select>
                                        <button
                                            onClick={handleAttachGroup}
                                            className="btn btn-outline-secondary"
                                            disabled={!isGroupSelected()}
                                            type="button">
                                            Применить
                                        </button>
                                    </div>
                                } </div>
                            : <Alert variant='danger'>
                                Отредактируйте сайт и выберите профиль <strong>cloudflare</strong>!
                            </Alert>}
                    </div>
                </Popover.Body>
            </Popover>
    );

    return <OverlayTrigger show={showed} trigger="click" onToggle={() => setShowed(!showed)} placement="top" overlay={popover}>
        <FontAwesomeIcon className={hasWafRules ? 'text-success text-opacity-75' : 'text-dark text-opacity-50'} icon={faShield}/>
    </OverlayTrigger>
}

export default ApplyWafRules
