import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";
import CloudflareDependentSelect from "../components/CloudflareDependentSelect";

export const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Поле обязательно для заполнения.'),
    registrarId: Yup.string().required('Поле обязательно для заполнения.')
})

export default function SitesBackupDomainsForm({cloudflareProfile, registrars, registrarProfile}) {
    const {values, handleChange, errors, initialValues} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="domain">
                {initialValues['domain'] === '' ? (
                        <>
                            <Form.Label>Домен или домены списком (Каждый домен с новой строки)</Form.Label>
                            <Form.Control
                                name="domain"
                                as="textarea"
                                type="text"
                                value={values.domain}
                                onChange={handleChange}
                                placeholder={`example1.com\nexample2.com\nexample3.com`}
                                isInvalid={!!errors.domain}
                                autoFocus
                            />
                        </>
                    ) :
                    (
                        <>
                            <Form.Label>Домен</Form.Label>
                            <Form.Control
                                name="domain"
                                type="text"
                                value={values.domain}
                                onChange={handleChange}
                                placeholder="Например: example.com"
                                isInvalid={!!errors.domain}
                                autoFocus
                            />
                        </>
                    )}
                <Form.Control.Feedback type="invalid">
                    {errors.domain}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="registrarId">
                <Form.Label>Регистратор</Form.Label>
                <Form.Select
                    name="registrarId"
                    value={values.registrarId}
                    onChange={handleChange}
                    isInvalid={!!errors.registrarId}
                    aria-label="Выберите регистратора"
                >
                    <option value="">Выберите профиль регистратора</option>
                    {registrarProfile.length > 0 ? registrarProfile.map(registrar => (
                        <option key={registrar.id}
                                value={registrar.id}>{registrar.title} ({registrars[registrar.registrarId].title})</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.registrarId}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Cloudflare</Form.Label>
                <CloudflareDependentSelect cloudflareProfile={cloudflareProfile}/>
            </Form.Group>
        </Form>
    )
}
