import {createContext, useState} from "react";

export const ApiWrapperContext = createContext();
const endpointUrl = process.env.REACT_APP_BACKEND_URL + '/api'

export default function ApiWrapperContextProvider({children, route, routeParams = {}}) {
    const [fetchedData, setFetchedData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItem, setCurrentItem] = useState({})
    const [lastFetchedDate, setLastFetchedDate] = useState(Date.now)

    const handlePageSelect = (event) => {
        const page = event?.target?.innerText;
        setCurrentPage(page || 1)
    }

    const editItem = (item) => {
        setCurrentItem({
            item,
            isEdit: true
        })
    }

    const deleteItem = (item) => {
        setCurrentItem({
            item,
            isEdit: false
        })
    }

    const createItem = () => {
        setCurrentItem({
            item: {},
            isEdit: false
        })
    }

    const contextValues = {
        handlePageSelect,
        editItem,
        createItem,
        deleteItem,
        currentPage,
        setCurrentPage,
        setFetchedData,
        fetchedData,
        route,
        routeParams,
        currentItem,
        setCurrentItem,
        endpointUrl,
        lastFetchedDate,
        setLastFetchedDate
    }

    return (
        <ApiWrapperContext.Provider value={contextValues}>
            {children}
        </ApiWrapperContext.Provider>
    )
}