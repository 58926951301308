import {ButtonGroup, Card, Table} from "react-bootstrap";
import {ApiWrapper, ApiPaginator, ApiAddButton, ApiConfirmModalDialog} from "../../../components/ApiWrapper";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";
import ButtonEdit from "../../../components/buttons/ButtonEdit";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import ModalContextProvider from "../../../context/ModalContext";
import GroupForm, {validationSchema} from "./GroupForm";
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";

export default function Group() {
    return (
        <ApiWrapperContextProvider route='cloudflare.group'>
            <ModalContextProvider>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className="d-flex justify-content-between">
                                Группы профилей CloudFlare
                                <ApiAddButton size="sm" variant="outline-dark" modalId="modalWithForm">Добавить</ApiAddButton>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            <Table borderless responsive size="sm">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Группа</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ApiWrapper>
                                    {({fetchedData, handleEdit, handleConfirmDelete}) => fetchedData?.items?.map(item => (
                                        <tr key={item.id}>
                                            <td>
                                                {item.id}
                                            </td>
                                            <td>
                                                {item.title}
                                            </td>
                                            <td width="20">
                                                <ButtonGroup className="mb-2">
                                                    <ButtonEdit onClick={() => handleEdit(item, 'modalWithForm')}/>
                                                    <ButtonDelete onClick={() => handleConfirmDelete(item, 'modalConfirm')}/>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ApiWrapper>
                                </tbody>
                            </Table>
                            <ApiPaginator/>
                        </Card.Text>
                    </Card.Body>
                </Card>

                <ApiModalFormWrapper windowId="modalWithForm" validationSchema={validationSchema} title="Изменение учетных данных">
                    <GroupForm/>
                </ApiModalFormWrapper>

                <ApiConfirmModalDialog windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalDialog>
            </ModalContextProvider>
        </ApiWrapperContextProvider>
    )
}
