import {
    Routes,
    Route
} from 'react-router-dom';
import AuthContextProvider from "./context/AuthContext";
import AxiosErrorHandler from "./AxiosErrorHandler";


function App({routes}) {
    return (
        <AuthContextProvider authorizeUrl={process.env.REACT_APP_BACKEND_URL + '/login'}>
            <AxiosErrorHandler>
                <Routes>
                    {routes.map(route => (
                        <Route key={route.to} path={route.to} element={route.element}>
                            {route.children.map(link => (
                                <Route key={link.to} exact path={link.to} element={link.element}/>
                            ))}
                        </Route>
                    ))}
                </Routes>
            </AxiosErrorHandler>
        </AuthContextProvider>
    );
}

export default App;
