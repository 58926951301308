import * as Yup from "yup";
import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import PasswordInput from "../../components/form/PasswordInput";

export const validationSchema = Yup.object().shape({
    userName: Yup.string().required('Поле обязательно для заполнения.'),
    email: Yup.string().email('Введите корректный email.').required('Поле обязательно для заполнения.'),
    status: Yup.string().required('Выберите статус'),
    role: Yup.string().required('Выберите роль')
})

export default function UserListForm({userMap}) {
    const {setFieldValue, values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Логин</Form.Label>
                <Form.Control
                    name="userName"
                    type="text"
                    value={values.userName}
                    onChange={handleChange}
                    isInvalid={!!errors.userName}
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    {errors.userName}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Например: example@mail.com"
                    isInvalid={!!errors.email}
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
                <Form.Label>Статус</Form.Label>
                <Form.Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    isInvalid={!!errors.status}
                    aria-label="Выберите статус"
                >
                    <option value="">Выберите статус</option>
                    {
                        Object.keys(userMap.statuses)
                            .map(status => (
                                <option key={status} value={status}>{userMap.statuses[status]}</option>
                            ))
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.status}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="role">
                <Form.Label>Роль</Form.Label>
                <Form.Select
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    isInvalid={!!errors.role}
                    aria-label="Выберите роль"
                >
                    <option value="">Выберите роль</option>
                    {
                        Object.keys(userMap.roles)
                            .map(role => (
                                <option key={role} value={role}>{userMap.roles[role]}</option>
                            ))
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.role}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Пароль</Form.Label>
                <PasswordInput
                    name="password"
                    value={values.password}
                    withPasswordGenerator={true}
                    onChange={(value) => setFieldValue('password', value)}
                    errors={errors}
                />
            </Form.Group>
            <hr/>
            <Form.Group className="mb-3" controlId="isXmlFeedActive">
                <Form.Check
                    type="switch"
                    name="isXmlFeedActive"
                    label="Включить сбор позиций"
                    checked={values.isXmlFeedActive}
                    onChange={(event) => {
                        setFieldValue('isXmlFeedActive', event.target.checked)
                    }}
                    isInvalid={!!errors.isXmlFeedActive}
                />
            </Form.Group>
        </Form>
    );
}