import {useEffect, useMemo, useRef} from "react";
import debounce from 'lodash/debounce';

const useDebounce = (callback, wait = 1000, options) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    return useMemo(() => {
        const func = (...argv) => {
            ref.current?.(...argv);
        };

        return debounce(func, wait, options);
    }, []);
};

export default useDebounce;