import {Form} from "react-bootstrap";
import {useFormikContext} from "formik";


import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    proxies: Yup.string().required('Поле обязательно для заполнения.'),
})

export default function ProxiesForm() {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="proxies">
                <Form.Label>Прокси списком (в формате логин:пароль@хост:порт)</Form.Label>
                <Form.Control
                    name="proxies"
                    as="textarea"
                    type="text"
                    value={values.proxies}
                    onChange={handleChange}
                    placeholder={`xxxxxxx:xxxxxxx@127.0.0.1:1234`}
                    isInvalid={!!errors.proxies}
                    autoFocus
                />
                <Form.Control.Feedback type="invalid">
                    {errors.proxies}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}
