import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    telegramUserName: Yup.string().required('Поле обязательно для заполнения.')
})

export default function NotificationForm() {
    const {values, handleChange, errors} = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="telegramUserName">
                <Form.Label>Никнейм</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Например: user1 (без символа @)"
                    name="telegramUserName"
                    value={values.telegramUserName}
                    onChange={handleChange}
                    isInvalid={!!errors.telegramUserName}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.telegramUserName}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}
