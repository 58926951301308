import {createContext, useCallback, useMemo, useState} from "react";

export const AuthContext = createContext();

function AuthContextProvider({authorizeUrl, children}) {
    const [isAuthenticated, setIsAuthenticated] = useState(window.localStorage.getItem('auth.token') !== null)
    const [error, setError] = useState();

    const handleLogin = useCallback((formValues) => {
        fetch(authorizeUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify({
                userName: formValues.userName,
                password: formValues.password
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response
                } else {
                    throw response
                }
            })
            .then(async (response) => {
                const data = await response.json();
                window.localStorage.setItem('auth.token', data?.token);
                window.localStorage.setItem('auth.user.id', data?.user?.id);
                window.localStorage.setItem('auth.user.userName', data?.user?.user_name);
                window.localStorage.setItem('auth.user.role', data?.user?.role)
                window.localStorage.setItem('auth.user.settings', JSON.stringify(data?.settings))

                setIsAuthenticated(true)
            })
            .catch(response => {
                if(response.status === 401) {
                    setError('Unauthorized')
                }
            })
    }, []);

    function handleLogout() {
        setIsAuthenticated(false)
        window.localStorage.clear();
    }

    const getToken = useCallback(() => {
        const authToken = window.localStorage.getItem('auth.token')

        if (authToken === null) {
            return new Error()
        }

        return authToken;
    }, [])

    const currentUser = useMemo(() => ({
            id: window.localStorage.getItem('auth.user.id'),
            userName: window.localStorage.getItem('auth.user.userName'),
            role: window.localStorage.getItem('auth.user.role'),
            settings: JSON.parse(window.localStorage.getItem('auth.user.settings'))
    }), [isAuthenticated])

    const contextValues = {
        isAuthenticated,
        error,
        getToken,
        currentUser,
        handleLogin,
        handleLogout
    }

    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider