import {Tab, Col, Card, ButtonGroup, Row} from "react-bootstrap";
import SitesNav from "../SitesNav";
import {useEffect, useState} from "react";
import 'react-data-grid/lib/styles.css';
import '../DisableBorder.css';
import DataGrid, {Row as DataGridRow} from "react-data-grid";
import ButtonEdit from "../../../components/buttons/ButtonEdit";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import styles from "../SiteCellBackground.module.css";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";
import ModalContextProvider from "../../../context/ModalContext";
import {ApiWrapper, ApiPaginator, ApiAddButton, ApiConfirmModalDialog} from "../../../components/ApiWrapper";
import SitesKeywordAddForm, {validationSchema} from "./SitesKeywordAddForm";
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";

export default function SitesPositionKeyword() {
    const [currentCategory, setCurrentCategory] = useState(false);
    const gridStyle = {
        minHeight: 400,
        blockSize: "100%"
    }

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none')
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none')
                }
            }
        })
    })

    function getColumns({handleEdit, handleConfirmDelete}) {
        return [
            {
                key: 'id',
                name: 'ID',
                width: '5%'
            },
            {
                key: 'keyword',
                width: '87%',
                name: 'Ключевое слово',
            },
            {
                key: 'action',
                width: '8%',
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return (
                        <ButtonGroup className="mb-2">
                            <ButtonEdit onClick={() => handleEdit(row, 'modalWithForm')}/>
                            <ButtonDelete onClick={() => handleConfirmDelete(row, 'modalConfirm')}/>
                        </ButtonGroup>
                    )
                }
            }
        ];
    }

    return (
        <ModalContextProvider>
            <Tab.Container id="left-tabs-example" activeKey={`nav_${currentCategory?.id}`}>
                <Row>
                    <Col xs={12} md={2}>
                        <SitesNav onNavSelect={(nav) => setCurrentCategory(nav)}/>
                    </Col>
                    <Col xs={12} md={10}>
                        {!currentCategory ? 'Загрузка...' :
                            <ApiWrapperContextProvider route='stat.category.keyword' routeParams={{
                                categoryId: currentCategory?.id
                            }}>
                                <Tab.Content>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="d-flex justify-content-between">
                                                    Ключевые слова
                                                    <ApiAddButton
                                                        size="sm"
                                                        variant="outline-dark"
                                                        modalId="modalWithForm"
                                                    >
                                                        Добавить
                                                    </ApiAddButton>
                                                </div>
                                            </Card.Title>
                                            <Card.Text>
                                                <Tab.Pane eventKey={`nav_${currentCategory.id}`}>
                                                    <ApiWrapper>
                                                        {(props) =>
                                                            <DataGrid
                                                                style={gridStyle}
                                                                showCellBorders={false}
                                                                columns={getColumns(props)}
                                                                rows={props.fetchedData.items}
                                                                renderers={{
                                                                    renderRow:
                                                                        (key, props) =>
                                                                            <DataGridRow
                                                                                key={key} {...props}
                                                                                electedCellIdx={undefined}
                                                                            />
                                                                }}
                                                            />
                                                        }
                                                    </ApiWrapper>
                                                    <ApiPaginator size="sm"/>
                                                </Tab.Pane>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab.Content>
                                <ApiConfirmModalDialog windowId="modalConfirm">
                                    <h3 className="text-center">Вы уверены?</h3>
                                </ApiConfirmModalDialog>
                                <ApiModalFormWrapper windowId="modalWithForm" validationSchema={validationSchema}
                                                     title="Изменение учетных данных">
                                    <SitesKeywordAddForm/>
                                </ApiModalFormWrapper>
                            </ApiWrapperContextProvider>
                        }
                    </Col>
                </Row>
            </Tab.Container>

        </ModalContextProvider>
    )
}
