import {Button, Card, Col, Row} from "react-bootstrap";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import {useContext, useEffect, useState} from "react";
import ApiManager from "../../../api";
import GroupItem from "./GroupItem";
import ModalDialog from "../../../components/modal/ModalDialog";
import {ModalContext} from "../../../context/ModalContext";

function RuleList() {
    const [groups, setGroups] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null)
    const {closeModal} = useContext(ModalContext);

    const apiManager = new ApiManager();

    useEffect(() => {
        apiManager
            .request('cloudflare.waf.group')
            .then(data => {
                setGroups(data.data)
            })
    }, [lastUpdated])

    const handleAddGroup = () => {
        apiManager
            .create('cloudflare.waf.group')
            .then(() => setLastUpdated(Date.now()))
    }

    function handleGroupDelete(payload) {
        if (payload?.groupId === undefined) return;

        apiManager
            .delete('cloudflare.waf.group', {}, {
                id: payload.groupId
            })
            .then(() => {
                closeModal('modalConfirmGroup', {isDeleted: true})
                setLastUpdated(Date.now())
            })
    }

    const handleRuleDelete = (payload) => {
        if (payload?.ruleId === undefined || payload?.groupId === undefined) return;

        apiManager
            .delete('cloudflare.waf.rule', {}, {
                groupId: payload.groupId,
                id: payload.ruleId
            })
            .then(() => closeModal('modalConfirmRule', {isDeleted: true}))
    }

    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Header>Правила WAF Cloudflare</Card.Header>
                    <Card.Body>
                        <Card.Title>Группы правил</Card.Title>
                        <ButtonOverlay tooltip="Новая группа правил">
                            <Button onClick={handleAddGroup} size="sm" variant="outline-success" className="m-1">
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </ButtonOverlay>
                            {groups.map(group => <GroupItem
                                key={group.id}
                                onDelete={() => setLastUpdated(Date.now())}
                                onUpdate={() => setLastUpdated(Date.now())}
                                group={group}/>)
                            }
                    </Card.Body>
                </Card>

                <ModalDialog windowId="modalConfirmGroup" onConfirm={handleGroupDelete}>
                    <h3 className="text-center">Вы уверены что хотите удалить группу правил?</h3>
                    <p className="text-center text-danger">Так же будет удален список правил из базы, правила у доменов в
                        CF останутся.</p>
                </ModalDialog>

                <ModalDialog windowId="modalConfirmRule" onConfirm={handleRuleDelete}>
                    <h3 className="text-center">Вы уверены что хотите удалить правило?</h3>
                    <p className="text-center text-info">Правило у доменов в CF останется.</p>
                </ModalDialog>
            </Col>
        </Row>
    )
}

export default RuleList
