import { useFormikContext } from "formik";
import { Button, Modal } from "react-bootstrap";
import { useContext } from "react"
import { ModalContext} from "../../context/ModalContext.js";

function ModalWithForm({ title, children, windowId, saveButtonTitle, closeButtonTitle }) {

    const { submitForm, isSubmitting, resetForm } = useFormikContext();
    const { isOpen, closeModal, windowId: currentWindowId } = useContext(ModalContext);

    const onClose = () => closeModal(windowId);

    return (
        <Modal centered show={ currentWindowId === windowId ? isOpen : false } onHide={ onClose }>
            <Modal.Header closeButton>
                <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { children }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ () => {
                    onClose();
                    resetForm();
                } }>
                    { closeButtonTitle || "Закрыть" }
                </Button>
                <Button variant="primary" disabled={ isSubmitting } onClick={ submitForm }>
                    { saveButtonTitle || "Сохранить" }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalWithForm;