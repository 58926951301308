import {Col, Row, Card, Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import ApiManager from "../../api";
import {getCellStyleForValue} from "../../utils/helper";
import styles from "./SiteCellBackground.module.css";
import Calendar from "../../components/Calendar";
import ButtonTable from "./components/ButtonTable";
import {DataGridMemorySortable} from "../../components/DataGridSortable";


function getComparator(sortColumn) {
    return (a, b) => {
        return a[sortColumn].sortPosition - b[sortColumn].sortPosition;
    };
}

export default function SitesDomainPosition() {

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const endDate = new Date();

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateRange, setDateRange] = useState({startDate, endDate})
    const {domain} = useParams();
    const navigate = useNavigate();

    function handleGoBack() {

        const hasPreviousPage = window.history.length > 1;

        if (hasPreviousPage) {
            navigate(-1)
        } else {
            navigate("/");
        }
    }

    useEffect(() => {
        new ApiManager()
            .request('stat.domain.position', {
                domain,
                startDate: dateRange?.startDate.toDateString(),
                endDate: dateRange?.endDate.toDateString()
            })
            .then(data => {

                const rows = [];
                const columns = [
                    {
                        key: 'keyword',
                        name: 'Ключевое слово',
                        frozen: true,
                        sortable: false
                    }
                ];

                data.dates.forEach((date, index) => {
                    columns.push({
                        key: date + index,
                        name: date,
                        renderCell: ({row}) => {
                            const cellValue = row[date + index];
                            const diffPosition = cellValue?.position - cellValue?.previousPosition;

                            if (cellValue?.previousPosition === 0) {
                                return <div>{cellValue?.position}</div>
                            } else if (diffPosition > 0) {
                                return <div>{cellValue?.position} <i className="text-danger">+{diffPosition}</i></div>
                            } else if (diffPosition < 0) {
                                return <div>{cellValue?.position} <i className="text-success">{diffPosition}</i></div>
                            } else if (cellValue?.position === 0 && cellValue?.previousPosition === 0) {
                                return <div>-</div>
                            }

                            return <div>{cellValue?.position}</div>
                        },
                        cellClass: (row) => {
                            const cellValue = row[date + index].position;
                            return getCellStyleForValue(cellValue, styles);
                        },
                    });
                });

                data.statistics.forEach(keywordPosition => {
                    const row = {
                        keyword: keywordPosition.query
                    }

                    data.dates.forEach((date, index) => {
                            let payload = {};
                            if (keywordPosition.positions[date]) {
                                const currentPosition = keywordPosition.positions[date].currentPosition;
                                payload.position = currentPosition;
                                payload.previousPosition = keywordPosition.positions[date].previousPosition;
                                payload.sortPosition = currentPosition === 0 ? 999999 : currentPosition;
                                row[date + index] = payload;
                            } else {
                                payload.position = 0;
                                payload.previousPosition = 0;
                                payload.sortPosition = 999999;
                                row[date + index] = payload;
                            }
                        }
                    )

                    rows.push(row)
                })

                setColumns(columns)
                setRows(rows)
            });
    }, [dateRange, domain]);

    return (
        <Row>
            <Col>
                <Button size="sm" variant="outline-secondary" onClick={handleGoBack}>Назад</Button>
                <hr/>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className="d-flex justify-content-between">
                                <div>Позиции <strong>{domain}</strong> за период с
                                    <strong> {dateRange?.startDate.toLocaleDateString()}</strong> по
                                    <strong> {dateRange?.endDate.toLocaleDateString()}</strong></div>

                                <Calendar
                                    selectRange={true}
                                    onChange={([startDate, endDate]) => setDateRange({startDate, endDate})}
                                    isHidden={showCalendar}
                                />

                                <ButtonTable
                                    tooltip="Показать календарь"
                                    onClick={() => setShowCalendar(!showCalendar)}
                                />
                            </div>
                        </Card.Title>
                        {rows.length === 0 ? 'Данных нет' :
                            <DataGridMemorySortable
                                setComparator={getComparator}
                                className="fill-grid"
                                defaultColDef={{flex: 1}}
                                defaultColumnOptions={{
                                    sortable: true
                                }}
                                columns={columns}
                                rows={rows}
                            />}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}