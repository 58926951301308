import {Button} from "react-bootstrap";
import {useContext} from "react";
import {ModalContext} from "../../context/ModalContext";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";

export default function ApiAddButton({modalId, children, ...rest}) {
    const {openModal} = useContext(ModalContext);
    const {createItem} = useContext(ApiWrapperContext)

    function handleCreateItem() {
        createItem()
        openModal(modalId)
    }

    return <Button {...rest} onClick={handleCreateItem}>{children}</Button>
}