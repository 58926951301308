import { useFormikContext } from "formik";
import {Form} from "react-bootstrap";
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    profileList: Yup.string().required('Поле обязательно для заполнения.'),
    group: Yup.string().required('Поле обязательно для заполнения.'),
})

export default function CloudFlareBatchForm({groups}) {
    const { values, handleChange, errors } = useFormikContext();

    return (
        <Form>
            <Form.Group className="mb-3" controlId="profileList">
                <Form.Label>Аккаунты Cloudflare (каждый с новой строки)</Form.Label>
                <Form.Control
                    name="profileList"
                    as="textarea"
                    type="text"
                    value={values.profileList}
                    onChange={handleChange}
                    placeholder={`name;login;password;api key\nname;login;password;api key\nname;login;password;api key`}
                    isInvalid={!!errors.profileList}
                    autoFocus
                />

                <Form.Control.Feedback type="invalid" style={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    whiteSpace: "pre-line",
                    maxHeight: "200px",
                    overflowY: "scroll"
                }}>
                    {errors.profileList}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="group">
                <Form.Label>Группы</Form.Label>
                <Form.Select
                    name="group"
                    value={values.group}
                    onChange={ handleChange }
                    isInvalid={ !!errors.group }
                    aria-label="Выберите группу"
                >
                    <option value="">Выберите группу</option>
                    {groups.length > 0 ? groups.map(group => (
                        <option key={group.id} value={group.id}>{group.title}</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    { errors.group }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
