import Navbar from "./Navbar";
import {Container} from "react-bootstrap";
import {Outlet, Navigate} from 'react-router-dom'
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";

export default function AppLayout() {
    const {isAuthenticated} = useContext(AuthContext)
    return (
        <>
            <Navbar/>
            <Container fluid>
                {isAuthenticated ? <Outlet /> : <Navigate
                    to={{
                        pathname: "/login"
                    }}
                />}
            </Container>
        </>
    )
}