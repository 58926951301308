import {Button, Modal} from "react-bootstrap";
import {useContext} from "react"
import {ModalContext} from "../../context/ModalContext.js";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";
import ApiManager from "../../api";

function ApiConfirmModalDialog({title, children, windowId}) {

    const {isOpen, closeModal, windowId: currentWindowId} = useContext(ModalContext);
    const {currentItem, fetchedData, route, routeParams, setLastFetchedDate, setCurrentPage} = useContext(ApiWrapperContext);

    const onClose = () => closeModal(windowId);

    const handleOnConfirm = () => {
        const apiManager = new ApiManager()

        apiManager.delete(route, currentItem.item, {
            id: currentItem.item.id,
            ...routeParams
        })
        closeModal(windowId);

        const currentPage = fetchedData.paginator.currentPage;

        if (currentPage > 1 && fetchedData?.items?.length === 1) {
            setCurrentPage(currentPage - 1)
        } else {
            setLastFetchedDate(Date.now());
        }
    }

    return (
        <Modal centered show={currentWindowId === windowId ? isOpen : false} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={onClose}>Нет</Button>
                <Button variant="danger" onClick={handleOnConfirm}>Да</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ApiConfirmModalDialog;