import {Form} from "react-bootstrap";
import {useFormikContext} from "formik";

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Поле обязательно для заполнения.'),
    ipAddress: Yup.string()
        .matches(
            /^(?:(?:25[0-5]|2[0-4]\d|1?\d?\d)(?:\.(?!$)|$)){4}$/,
            'Неправильно введен ip адрес'
        )
        .required('Поле обязательно для заполнения.'),
    user: Yup.string().required('Поле обязательно для заполнения.'),
    password: Yup.string()
        .min(8, "Введен неправильный пароль")
        .required('Поле обязательно для заполнения.')
})

export default function ServersForm() {
    const {values, handleChange, errors} = useFormikContext();
    return (
        <Form>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Имя сервера</Form.Label>
                <Form.Control
                    name="title"
                    type="text"
                    value={ values.title }
                    onChange={ handleChange }
                    isInvalid={ !!errors.title }
                    placeholder="Например: Сервер 1"
                />
                <Form.Control.Feedback type="invalid">
                    { errors.title }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ipAddress">
                <Form.Label>Ip адрес</Form.Label>
                <Form.Control
                    name="ipAddress"
                    type="text"
                    value={ values.ipAddress }
                    onChange={ handleChange }
                    isInvalid={ !!errors.ipAddress }
                    placeholder="Например: 127.0.0.1"
                />
                <Form.Control.Feedback type="invalid">
                    { errors.ipAddress }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="user">
                <Form.Label>Имя пользователя</Form.Label>
                <Form.Control
                    name="user"
                    type="text"
                    value={ values.user }
                    onChange={ handleChange }
                    isInvalid={ !!errors.user }
                    placeholder="Например: root"
                />
                <Form.Control.Feedback type="invalid">
                    { errors.user }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Пароль</Form.Label>
                <Form.Control
                    name="password"
                    type="password"
                    value={ values.password }
                    onChange={ handleChange }
                    isInvalid={ !!errors.password }
                />
                <Form.Control.Feedback type="invalid">
                    { errors.password }
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}