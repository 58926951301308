import axios from "axios";

export default axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        Accept: 'application/json'
    },
    transformRequest: [function (data, headers) {
        headers['Authorization'] = 'Bearer ' + window.localStorage.getItem('auth.token')
        return data;
    }],
});