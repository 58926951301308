import {Button, ButtonGroup, Modal} from "react-bootstrap";
import {useContext, useEffect, useRef, useState} from "react";
import {ModalContext} from "../../../context/ModalContext";
import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import {Rule} from "./CloudflareRedirect"
import ApiManager from "../../../api";
import {Formik, Form, FieldArray} from 'formik';
import AlertDismissible from "../../../components/AlertDismissible";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    rules: Yup.array().of(Yup.object().shape({
            redirectType: Yup.string(),
            targetUrl: Yup.string().required('Введите Url'),
            expression: Yup.string().when('redirectType', {
                is: v => v === 'filter',
                then: schema => schema.required('Правило не должно быть пустым')
            }),
        }
    ))
});

export default function SitesListRedirectModal({windowId, siteId, ...rest}) {
    const {isOpen, closeModal, windowId: currentWindowId} = useContext(ModalContext);
    const [rules, setRules] = useState([]);

    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitVariant, setSubmitVariant] = useState('success');
    const [submitMessage, setSubmitMessage] = useState('');

    const [isRulesUpdating, setIsRulesUpdating] = useState(false);

    const onClose = () => closeModal(windowId);
    const formRef = useRef();

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    const handleFormSubmit = (values) => {
        setIsRulesUpdating(true);

        new ApiManager()
            .create('sites.cloudflare.redirect-rule', {...values}, {siteId})
            .then(response => {
                if (response.data.message === 'success') {
                    setSubmitStatus(true)
                    setSubmitVariant('success')
                    setSubmitMessage(<><strong>Успешно.</strong> Редиректы сохранены</>)
                } else {
                    setSubmitStatus(true)
                    setSubmitVariant('danger')
                    setSubmitMessage(<><strong>Ошибка.</strong> ${response.data.message}</>)
                }

                setIsRulesUpdating(false);
            })
    }

    useEffect(() => {
        if (currentWindowId !== windowId) return;

        if (!isOpen) {
            setRules([]);
            return;
        }

        new ApiManager()
            .request('sites.cloudflare.redirect-rule', {siteId})
            .then(response => setRules(response.data))
    }, [siteId, isOpen, currentWindowId, windowId])

    return (
        <Modal {...rest}
               show={currentWindowId === windowId ? isOpen : false}
               onHide={() => closeModal(windowId)}
               fullscreen={true}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Настройка редиректов Cloudflare
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <AlertDismissible
                    show={submitStatus}
                    onClose={() => setSubmitStatus(false)}
                    variant={submitVariant}>
                    {submitMessage}
                </AlertDismissible>

                <Formik
                    innerRef={formRef}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    initialValues={{rules}}
                    onSubmit={handleFormSubmit}
                >
                    {({values, handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                            <FieldArray name="rules">
                                {({remove, swap, push}) => (
                                    <div>
                                        <Button
                                            onClick={() => push({
                                                expression: '',
                                                targetUrl: '',
                                                redirectCode: 301,
                                                redirectType: 'static'
                                            })}
                                        >
                                            +
                                        </Button>

                                        {values.rules.length > 0 && values.rules.map((rule, index) => <Rule
                                            key={index}
                                            index={index}
                                            place={index + 1}
                                            handleMoveUp={() => index > 0 ? swap(index, index - 1) : swap(index, values.rules.length - 1)}
                                            handleMoveDown={() => index < values.rules.length - 1 ? swap(index, index + 1) : swap(values.rules.length - 1, 0)}
                                            handleRemove={() => remove(index)}
                                        />)}
                                    </div>
                                )}
                            </FieldArray>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <ButtonOverlay tooltip="Применить и сохранить для текущего домена">
                        <Button disabled={isRulesUpdating} onClick={handleSubmit} variant="info">
                            {isRulesUpdating ? <><FontAwesomeIcon spin={true} icon={faSpinner} /> Ждите...</> : 'Применить & Сохранить'}
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay tooltip="Закрыть текущее модальное окно">
                        <Button variant="danger" onClick={onClose}>Закрыть</Button>
                    </ButtonOverlay>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}
