import {Nav} from "react-bootstrap";
import {ApiWrapper} from "../../components/ApiWrapper";
import {useContext, useEffect} from "react";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";

export default function SitesNav({onNavSelect}) {
    const {fetchedData} = useContext(ApiWrapperContext)

    useEffect(() => {
        if (fetchedData?.items?.length > 0) {
            const firstNav = [...fetchedData?.items].shift();
            onNavSelect(firstNav)
        }
    }, [fetchedData])

    return (
        <Nav variant="pills" className="flex-column">
            <ApiWrapper>
                {({fetchedData}) => fetchedData?.items?.map((item) => (
                    <Nav.Item key={item.id}>
                        <Nav.Link eventKey={"nav_" + item.id} onClick={() => onNavSelect(item)}>{item.title}</Nav.Link>
                    </Nav.Item>
                ))}
            </ApiWrapper>
        </Nav>
    )
}