import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './CalendarWrapper.css';

export default function CalendarWrapper({isHidden, ...rest}) {
    return isHidden && (
        <div className="calendar-wrapper">
            <Calendar
                view={'month'}
                locale={'ru'}
                {...rest}
            />
        </div>
    )
}